import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import studentCommonApi from "../../../../../services/_student_skillup_api";
import studentGlobalDataStore from "../../../../../store/_global_studentData";
import Loader from "../../../../../common/loader";
import Loader3 from "../../../../../common/loader3";

export default function AllProjects() {
  const { getStudentProjects } = studentCommonApi();
  const { liveClass, setLiveClass } = studentGlobalDataStore();
  const [data, setData] = useState<any>(null);
  console.log(liveClass);
  useEffect(() => {
    if (localStorage.getItem("live-class")) {
      setLiveClass(JSON.parse(localStorage.getItem("live-class") || ""));
    }
    getData();
  }, []);
  const getData = async () => {
    const res: any = await getStudentProjects(liveClass.course_id);
    console.log(res);
    setData(res);
  };
  return (
    <>
      <>
        {data ? (
          <>
            {data.length ? (
              <>
                {" "}
                <>
                  <div className="container enrolled p-2">
                    <h5 className="heading">All Projects</h5>
                    <div className="row">
                      {data.map((x: any) => (
                        <div className="col-sm-3">
                          <div className="card assignment-card p-3">
                            <div className="flex-center flex-column">
                              <img
                                src="/assets/student/online-homework-help.png"
                                alt=""
                              />
                              <p className="text-center">Projects</p>
                            </div>
                            <div className="text-start">
                              <h5 className="heading">{x.project_name}</h5>
                            </div>
                            <Link
                              to="/StudentClasses/Projects/AssignedProjectsDetails"
                              state={x}
                            >
                              <button className="btn btn-sm mt-2 btn-primary btn-wide">
                                See Details
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              </>
            ) : (
              <>
                <Loader3 />
              </>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </>
    </>
  );
}
