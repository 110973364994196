import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/_auth";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [creeds, setCreeds] = useState<any>({
    username: "",
  });
  const { resetPassword } = AuthService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onValueChange = (val: any) => {
    // console.log(val);
    setCreeds({ ...creeds, ...val });
    // console.log(val);
    // console.log(register);
  };
  const onSendMail = async () => {
    // console.log(creeds);
    setLoading(true);
    let response: any = await resetPassword(creeds);
    console.log(response);
    if (response == 0) {
         toast.error("Invalid email");
      setCreeds({
        username: "",
      });
     // setError(true);
    } else {
      toast.success(`Mail sent to ${creeds.username}`);
    }
    setLoading(false);
  };
  return (
    <>
      <div className=" container-fluid register-background ">
        <div className="row">
          <div className="col-md-7 register-text p-3">
            <div className="text">
              <h1 className="title-primary">Forgot Password!</h1>
              {/* <h4 className="subtitle-primary">
                Get 100+ courses in developing a future-proof career in solid
                and impactful manpower in the security and Information Systems
                as per international trend.
              </h4> */}
            </div>
          </div>
          <div
            className="col-md-5 bg-white flex-center p-3"
            style={{ minHeight: "100vh" }}
          >
            <div className="mx-auto my-auto form register-form">
              <div className=" p-2 mt-5 d-flex">
                <div className="logo-reg  mx-auto">
                  <img src="/assets/svg/Logo.svg" alt="LOGO" />
                </div>
              </div>
              {/* <div className="mx-auto">
                            <p id="error" className="error">*This is an error message*</p>
                        </div> */}
              {/* <div className="slides">
                            <div className="slide1"> */}
              <form>
                <div
                  className="p-5"
                  style={{
                    minHeight: "30rem !important",
                    maxHeight: "30rem!important",
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username/ Email{" "}
                      {error && (
                        <span className="text-danger">
                          Invalid username/ email
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        onValueChange({
                          [e.target.name]: e.target.value,
                        })
                      }
                      className={`form-control ${error && "invalid"}`}
                      name="username"
                      value={creeds.username}
                      id="username"
                      aria-describedby="namelHelp"
                      placeholder="example@email.com"
                      onFocus={() => setError(false)}
                      required
                    />
                  </div>

                  <div className="mb-3 ">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary btn-wide "
                      disabled={loading}
                      onClick={onSendMail}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {loading ? "Sending..." : "Send to mail"}
                    </button>
                  </div>

                  <div className="mx-auto mt-4">
                    <h5 className="subtitle-primary text-center  ">
                      Already have account ? <a href="/login">Login now!</a>
                    </h5>
                  </div>
                </div>
              </form>

              {/* </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
