import { Columns } from "../../../interfaces/_common";
import globalDataStore from "../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../services/_protected_api";
import PrimeDataTable from "../../../common/prime_data_table";
import { Link, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import userState from "../../../store/_userState";
export default function Viewpayment(){
    const { user } = userState();
    const {get_payment_details_by_gen, delete_payment_by_mktr} = protectedApiService();

const [get_payment, setget_payment] = useState([]);

useEffect(()=>{
    get_payment_details();
},[])


const deletepayment = async(data)=>{
  //console.log(data);

const payment_receive_id = data.payment_receive_id;

const conf = window.confirm("Are you sure to delete?");
if(conf==true){
  var ddt = await delete_payment_by_mktr(payment_receive_id,user.user_id);

  if(ddt==1){
    toast.error("Data deleted successfully");
  }

}

}

const get_payment_details = async()=>{

    const data = await get_payment_details_by_gen(user.user_id);
    data.forEach(element => {
    //  console.log(element);
      if(element["due_emi"]!=null){
      var str = element["due_emi"].toString();
      var data2 = str.replace( /(<([^>]+)>)/ig, '');
      element["due_emii"] = data2;
      }else{
        element["due_emii"] = null;
      }
    });
    setget_payment(data);
}

    const tablesStructure: Columns[] = [

        {
            data_name: "bill_no",
            header: "Bill No",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "cohort",
            header: "Batch No",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "name",
          header: "Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "email",
            header: "Email",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "course_name",
          header: "Course Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
          data_name: "course_price",
          header: "Course Fees",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "deposite_amount",
            header: "Deposit Amount",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "due_emii",
            header: "Due/Emi Amount",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char" dangerouslySetInnerHTML={{__html: data[key]}}></span>
                </>
              ) || <></>,
          },
          {
            data_name: "payment_mode",
            header: "Payment Mode",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "next_payment_date",
            header: "Next Payment Date",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "pdf_invoice",
            header: "Invoice",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">
                    <a href={data[key]} download target="_blank" className="p-button-rounded p-button-primary p-button-outlined">Download</a>
                  </span>
                </>
              ) || <></>,
          },
          {
            data_name: "created_at",
            header: "Invoice Generate Date & Time",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "operation",
          header: "Operation",
          sortable: false,
          dataFilter: (data: any, key: any) => {
            return (
              <>
                 {/* <Link to="/Home/Career/Edit Jobs" state={data}>
                  <Button
                    icon="pi pi-file-edit"
                    className="p-button-rounded p-button-primary p-button-outlined"
                    aria-label="Delete"
                  />
                </Link>*/}
               <Button
                  onClick={() => deletepayment(data)}
                  icon="pi pi-trash
                    "
                  className="mx-2 p-button-rounded p-button-danger p-button-outlined"
                  aria-label="Delete"
                /> 
              </>
            );
          },
        },
      ];
    return(
        <>
              <div className="das-exs ">
        <div className="flex-start mx-4">
         {/* <p>View Payment</p> */}
        </div>
      </div>
      <PrimeDataTable
        data={get_payment || []}
        structure={tablesStructure}
        title={"View Payment"}
        onRefresh={get_payment_details}
       // filterDropdown={[{ filter: "course_name", header: "course_name", type:"job_search" }]}
        newdropdown
      />
        </>
    )
}