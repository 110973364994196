import { useEffect, useState, useCallback } from "react";

import { GiPadlock, GiPadlockOpen } from "react-icons/gi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";

import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RxDoubleArrowLeft } from "react-icons/rx";
import $ from "jquery";
import studentSkillUpApi from "../../../../../services/_student_skillup_api";
import studentGlobalDataStore from "../../../../../store/_global_studentData";
import Loader from "../../../../../common/loader";
import Loader2 from "../../../../../common/loader2";
import PrimeDataTable from "../../../../../common/prime_data_table";
import { Columns } from "../../../../../interfaces/_common";

export default function StudentSkillUpStats() {

  const location = useLocation();
  const profile = location.state[0];

console.log(profile);

  const tablesStructure: Columns[] = [
    {
      data_name: "title",
      header: "Title",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "watch",
      header: "Watched Percent",
      sortable: false,
      dataFilter: (data: any, key: any) =>
        (
          <>
            <ProgressBar
              style={{ width: "100%" }}
              variant="success"
              now={data.completed_percent}
              label={`${Math.floor(data.completed_percent)}%`}
            />
          </>
        ) || <></>,
    },
  ];
  const { skillUpModule, setSkillUpModule } = studentGlobalDataStore();
  const { getSkillUpModulesByUser } = studentSkillUpApi();
  const [modules, setModules] = useState<any>(null);
  const [keys, setKeys] = useState<any>(null);
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    if (localStorage.getItem("skill-up")) {
      setSkillUpModule(JSON.parse(localStorage.getItem("skill-up") || ""));
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);
  function groupBy(arr: any, property: any) {
    return arr.reduce(function (memo: any, x: any) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  const getData = async () => {
    const res: any = await getSkillUpModulesByUser(profile.user_id);
    console.log(res);
    setModules(groupBy(res.data, "course_id"));
    setKeys(Object.keys(groupBy(res.data, "course_id")));
  };

  return (
    <>
      <>
        {modules ? (
          <>
            {keys.length ? (
              <>
                {" "}
                {keys.map((k) => (
                  <>
                    <PrimeDataTable
                      data={modules[k] || null}
                      structure={tablesStructure}
                      title={modules[k][0].course_name}
                      isForStudents
                      message
                      noSearch
                      noChecks
                    />
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
}
