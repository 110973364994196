import studentGlobalDataStore from "../../../../store/_global_studentData";
import { useEffect, useState, useRef } from "react";
import studentCommonApi from "../../../../services/_student_skillup_api";
import { Link, useNavigate } from "react-router-dom";
export default function Liveinterectivelesson() {
  const { skillUpModule, setSkillUpModule } = studentGlobalDataStore();
  const { get_lesson_course } = studentCommonApi();

  const [get_result, setget_result] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    get_student_lesson();
  }, []);

  const get_student_lesson = async () => {
    const class_det = localStorage.getItem("storage_course_id");
    const get_lesson = await get_lesson_course(class_det, "paid");
    console.log(get_lesson);
    setget_result(get_lesson);
  };

  const go_to_skillup = () => {
    localStorage.setItem("source", "paid");
    // navigate("/SkillUp/lesson start", {
    //   state: {x},
    // });
  };

  return (
    <>
      <div className="rww">
        <h3 className="fw-bold pb-2"></h3>
        <div className="row">
          {get_result.map((v, index) => {
            return (
              <>
                <div className="col-sm-3">
                  <div className="card lesson-card p-3">
                    <div className="flex-center flex-column">
                      <img src="/assets/svg/video-lesson.svg" alt="" />
                    </div>
                    <div className="text-start">
                      <p className="text-dark">Lesson - {index + 1}</p>
                      <p className="heading mt-2">{v.lesson_name || ""}</p>
                    </div>
                    <Link to="/SkillUp/lesson start" state={v}>
                      <button className=" btn btn-primary wide-btn ">
                        Show
                      </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div
                    className="card card_prop"
                    style={{ margin: "15px auto" }}
                  >
                    <img
                      src={"/assets/bg/register_bg.png"}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h6 className="card-title fw-bold">
                        {v.lesson_name || ""}
                      </h6>

                      <Link to="/SkillUp/lesson start" state={v}>
                        <button
                          className=" btn btn-success btn_retake"
                          onClick={() => {
                            go_to_skillup();
                          }}
                        >
                          {" "}
                          Show
                        </button>
                      </Link>

                      <button className=" btn btn-success btn_retake" onClick={()=>{go_to_skillup(v)}}> Show</button>
                    </div>
                  </div>
                </div> */}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
