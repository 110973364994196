import { useLocation } from "react-router-dom";
import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
export default function Quizlist(){
    const [allData, setallData] = useState([]);
    const [allCourses, setallCourses] = useState([]);
    const location = useLocation();
    const profile = location.state;
    const { get_quiz_data, get_student_all_courses, all_quiz_data_list, insert_quiz_questions, mcq_quiz_del } = protectedApiService();
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const [allquestions, setallquestions] = useState([]);
const arkk = [{
  "mcq_question_id":"",
  "question":""
}]

const qq = {
  "quiz_title":"",
  "course_id":"",
  "total_questions":"",
  "quiz_duration":""
}


const [quiz_get_all_data, setquiz_get_all_data] = useState<any>(qq);


    const [get_filtered_questions, setget_filtered_questions] = useState<any>(arkk);


    

    const [selected_question_data_list, setselected_question_data_list] = useState([]);


    const onValueChange = (val: any) => {
      // console.log(val);
      setquiz_get_all_data({ ...quiz_get_all_data, ...val });
     // console.log(val);
      // console.log(register);
    };

    const tablesStructure: Columns[] = [
        {
          data_name: "course_name",
          header: "Course Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "total_question",
            header: "Total Questions",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "quiz_duration",
            header: "Quiz Duration",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "quiz_times",
            header: "Quiz Attemped",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "is_active",
            header: "Quiz Status",
            sortable: true,
            dataFilter: (data: any, key: any) =>{
            if(data["is_active"]==1){
return (
    <>
   
      <span className="limited-char" style={{color:"green"}}>Active</span>
    </>
  )
            }else{
                return (
                    <>
                   
                      <span className="limited-char" style={{color:"red"}}>Inactive</span>
                    </>
                  )
            }
        }
          },

        // {
        //   data_name: "duration",
        //   header: "Duration",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //   data_name: "description",
        //   header: "Course Description",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //     data_name: "classes",
        //     header: "Total Classes",
        //     sortable: true,
        //     dataFilter: (data: any, key: any) =>
        //       (
        //         <>
        //           <span className="limited-char">{data[key]}</span>
        //         </>
        //       ) || <></>,
        //   },
          {
            data_name: "operation",
            header: "Operation",
            sortable: false,
            dataFilter: (data: any, key: any) => {
              return (
                <>

{/* <button className="btn btn-success">Edit</button>
 &nbsp; &nbsp; */}
                <button className="btn btn-danger" onClick={()=>{delete_quiz_data(data)}}>Delete</button>
                 {/* <Link to="/Home/mcq-quiz/Course wise questions" state={data}>
                     <Button
                      icon="pi pi-file-edit"
                      className="p-button-rounded p-button-primary p-button-outlined"
                      aria-label="Delete"
                    /> 
                    <button className="btn btn-primary">Course wise questions</button>
                  </Link>*/}
                  
                </>
              );
            },
          },
      ];





      const question_datatable: Columns[] = [
        {
          data_name: "question",
          header: "Question",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
          {
            data_name: "operation",
            header: "Operation",
            sortable: false,
            dataFilter: (data: any, key: any) => {
              return (
                <>
                <button className="btn btn-success" onClick={()=>{update_quiz_data(data)}}>Edit</button>
                <button className="btn btn-danger" onClick={()=>{delete_quiz_data(data)}}>Delete</button>
                 {/* <Link to="/Home/mcq-quiz/Course wise questions" state={data}>
                     <Button
                      icon="pi pi-file-edit"
                      className="p-button-rounded p-button-primary p-button-outlined"
                      aria-label="Delete"
                    /> 
                    <button className="btn btn-primary">Course wise questions</button>
                  </Link>*/}
                  
                </>
              );
            },
          },
      ];



      useEffect(()=>{
        get_quiz();
        get_all_courses();
        all_quiz_data();
    },[])
    
    
    const get_quiz = async()=>{
        const data = await get_quiz_data();
        //console.log(data);
        setallData(data);
    }


const get_all_courses = async() =>{
  const data = await get_student_all_courses();
 // console.log(data);
  setallCourses(data);
}


const all_quiz_data = async()=>{
  const data = await all_quiz_data_list();
  //console.log(data)
  setallquestions(data);
}


const course_selected_data = (data)=>{
  var filter_data = [];
  allquestions.forEach((vv)=>{
   // console.log(vv);
    if(vv["course_id"]==data){
     
      filter_data.push(vv);
    }
  })

  quiz_get_all_data["course_id"] = data
  setquiz_get_all_data(quiz_get_all_data);
//console.log(filter_data);
  setget_filtered_questions(filter_data);

}


const course_selected_data_question = (data)=>{
if(data==""){
  toast.error("Please select option");
}else{


  var ddtt = [];

  get_filtered_questions.forEach((ll)=>{
    if(ll["mcq_question_id"]==data){
      selected_question_data_list.push(ll);
    }

    if(ll["mcq_question_id"]!=data){

      ddtt.push(ll);
     
    }


  })

  //console.log(ddtt);

  setget_filtered_questions(ddtt);

  setselected_question_data_list(selected_question_data_list);

}
}

const delete_from_list = (data)=>{
  //console.log(data);
  const del = window.confirm("Are you sure to delete?");
  if(del==true){
    var empy = [];
    var othr = [];
    selected_question_data_list.forEach((ck)=>{
      if(ck["mcq_question_id"]!=data.mcq_question_id){
        empy.push(ck);
      }

      if(ck["mcq_question_id"]==data.mcq_question_id){
        get_filtered_questions.push(ck);
      }

    })
    setget_filtered_questions(get_filtered_questions);
    setselected_question_data_list(empy);

  }
}


const submit_quiz_question = async()=>{
  // console.log(quiz_get_all_data);
  // console.log(selected_question_data_list);
  if(quiz_get_all_data.quiz_title==""){
    toast.error("Please add quiz title");
  }else if(quiz_get_all_data.course_id==""){
    toast.error("Please select course");
  }else if(quiz_get_all_data.total_questions==""){
    toast.error("Please add total question");
  }else if(quiz_get_all_data.quiz_duration==""){
    toast.error("Please add quiz duration");
  }else if(selected_question_data_list.length==0){
    toast.error("Please add question");
  }else{
    var aluu = [];

    selected_question_data_list.forEach((vk)=>{
      aluu.push(vk["mcq_question_id"])
    })

    quiz_get_all_data["mcq_questions"] = aluu;

 //   console.log(quiz_get_all_data);

const dkdu = await insert_quiz_questions(quiz_get_all_data);
if(dkdu==1){
  toast.success("Successfully added");
}
  }
}

const delete_quiz_data = async(data)=>{
  const dkk = window.confirm("Are you sure?");
  if(dkk==true){
    const ddtt = await mcq_quiz_del(data.mcq_quiz_id);
    if(ddtt==1){
      toast.error("Deleted successfully");
      get_quiz();
    }
  }

}


const update_quiz_data = (data)=>{
  console.log(data);
}


    return(
        <>
        <div className="das-exs ">
          <div className="flex-end mx-4">
          <button className="btn btn-success" onClick={()=>{setDeleteModalShow(true)}}>Add Quiz</button>
          </div>
        </div>
        <PrimeDataTable
          data={allData || []}
          structure={tablesStructure}
          title={"Quiz List"}
          onRefresh={get_quiz}
        />

<Modal
            fullscreen={true}
            aria-labelledby="example-custom-modal-styling-title"
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
              
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
         
            <div className="container view-student" style={{maxWidth:"95%"}}>
            <div className="main-body">
              <div className="row gutters-sm">
             
              <div className="col-md-3">
                          <h6>Quiz Title</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="quiz_title"
                            id="course_name"
                            value={quiz_get_all_data.quiz_title}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Add Question"
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                             onChange={(e) =>
                              onValueChange({
                                [e.target.name]: e.target.value,
                              })
                            }
                           
                          />
                        </div>

                        <div className="col-md-3">
                          <h6>Course Name</h6>
                          <select
                           
                            className="form-control"
                            name="course_id"
                            id="course_name"
                           // value={creeds.question}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Add Question"
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }

                            onChange={(e)=>{course_selected_data(e.target.value)}}
                           
                          >
                            <option value="">Select One</option>
                            {allCourses.map((co,index)=>{
                              return(
                                <>
                                <option value={co.course_id}>{co.course_name}</option>
                                </>
                              )
                            })}

                          </select>
                        </div>

                        <div className="col-md-3">
                          <h6>Total Questions</h6>
                          <input
                            type="number"
                            className="form-control"
                            name="total_questions"
                            id="course_name"
                            value={quiz_get_all_data.total_questions}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Total Questions"
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                             onChange={(e) =>
                              onValueChange({
                                [e.target.name]: e.target.value,
                              })
                            }
                           
                          />
                        </div>



                        <div className="col-md-3">
                          <h6>
                            Quiz Duration
                          </h6>
                          <input
                            type="number"
                            className="form-control"
                            name="quiz_duration"
                            id="course_name"
                           // value={creeds.question}
                           value={quiz_get_all_data.quiz_question}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Enter Time (Minutes)"
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                             onChange={(e) =>
                              onValueChange({
                                [e.target.name]: e.target.value,
                              })
                            }
                           
                          />
                        </div>





              </div>
              <div className="row">
              <div className="col-md-12">
                          <h6>Questions</h6>
                          <select
                           
                            className="form-control"
                            name="quiz_question_id"
                            id="course_name"
                           // value={creeds.question}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Add Question"
                            style={{maxWidth:"100%"}}
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }

                            onChange={(e)=>{course_selected_data_question(e.target.value)}}
                           
                          >
                            <option value="">Select One</option>
                            {get_filtered_questions.map((co,index)=>{
                              return(
                                <>
                                <option value={co.mcq_question_id}>{co.question}</option>
                                </>
                              )
                            })}

                          </select>
                        </div>
              </div>
              <div className="row" style={{margin:"30px"}}>
                 <div className="col-md-12">
             <table style={{width:"80%"}}>
              <tr>
                <th>Sl.No</th>
                <th>Question</th>
                <th>Action</th>
              </tr>
                   {selected_question_data_list.map((sqdl,index)=>{
                    return(
                      <>
                       <tr style={{}}>
                        <td>{index+1}</td>
                <td style={{padding:"5px",  margin:"1px"}}>{sqdl.question}</td>
                <td style={{padding:"5px",  margin:"1px"}}>
                    <button className="btn btn-danger" onClick={()=>{delete_from_list(sqdl)}}>Delete</button>
                </td>
              </tr>
                      </>
                    )
                   })}
             
             </table>
                 </div>
              </div>
              <div className="row">
                        <div className="col-sm-12" style={{marginTop:"20px"}}>
                          <button
                           onClick={() => submit_quiz_question()}
                            className="btn btn-info"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
            </div>
          </div>

            </Modal.Body>
            <Modal.Footer>
              <button
             //   onClick={() => close_btn_click()}
                className="btn btn-info btn-sm"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

        </>
    )
}