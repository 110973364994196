import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../services/_auth";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { Password } from "primereact/password";
export default function NewPassword() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [creeds, setCreeds] = useState<any>({
    password: "",
    confirmpass: "",
    encemail: id,
  });
  const { newPassword } = AuthService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onValueChange = (val: any) => {
    error && setError(false);
    setCreeds({ ...creeds, ...val });
    // console.log(val);
    // console.log(register);
  };
  const onSendNewPassword = async () => {
    // console.log(creeds);
    if (creeds.password == creeds.confirmpass && creeds.encemail) {
      setLoading(true);
      let response: any = await newPassword(creeds);
      if (response.status == 0) {
        if (response.msg == "Link Expired. Please forgot password again") {
          toast.error(response.msg);
          navigate("/reset-password");
        } else {
          toast.error("Something went's wrong!");
          setCreeds({
            password: "",
            confirmpass: "",
          });
          setError(true);
        }
      } else {
        toast.success("Changed");
        navigate("/login");
      }
      setLoading(false);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="row register-background ">
        <div className="col-sm-7 register-text p-5">
          <div className="text">
            <h1 className="title-primary">Create new Password!</h1>
            <h4 className="subtitle-primary"></h4>
          </div>
        </div>
        <div className="col-sm-5 bg-white flex-center p-3">
          <div className="mx-auto my-auto form register-form">
            <div className=" p-2 mt-5 d-flex">
              <div className="logo  mx-auto">
                <img src="/assets/svg/Logo.svg" alt="LOGO" />
              </div>
            </div>
            {/* <div className="mx-auto">
                            <p id="error" className="error">*This is an error message*</p>
                        </div> */}
            {/* <div className="slides">
                            <div className="slide1"> */}
            <form>
              <div
                className="p-5"
                style={{
                  minHeight: "30rem !important",
                  maxHeight: "30rem!important",
                }}
              >
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    New Password{" "}
                  </label>
                  <Password
                    name="password"
                    id="password"
                    placeholder="Password*"
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                    value={creeds.password}
                    className={`w-100 ${error && "invalid"}`}
                    toggleMask
                    feedback={false}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Confirm Password{" "}
                  </label>
                  <Password
                    name="confirmpass"
                    id="confirmpass"
                    placeholder="Confirm Password*"
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                    value={creeds.confirmpass}
                    className={`w-100 ${error && "invalid"}`}
                    toggleMask
                  />

                  <p className=" mt-1">
                    *Use upper & lower case with minimum one number and one
                    symbol{" "}
                  </p>
                </div>

                <div className="mb-3 ">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-primary btn-wide "
                    disabled={loading}
                    onClick={onSendNewPassword}
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    {loading ? "Changing..." : "Change password"}
                  </button>
                </div>
                <div className="mx-auto mt-4">
                  <h5 className="subtitle-primary text-center  ">
                    Already have account ? <a href="/login">Login now!</a>
                  </h5>
                </div>
              </div>
            </form>

            {/* </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
