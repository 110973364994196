import { useState, useEffect } from "react";
import "react-image-picker-editor/dist/index.css";
import protectedApiService from "../../../../services/_protected_api";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { Chips } from "primereact/chips";
import commonApiService from "../../../../services/_common_api";
import globalDataStore from "../../../../store/_globalData";
import { Editor } from "primereact/editor";
export default function AddJobs() {
  const init: any = {
    title: "",
    location: "",
    company: "",
    experience: "",
    terms_condition: "",
    course_id: "",
    duration: "Full Time",
    package: "",
  };
  const [job_description, onTextChangee] = useState<string>("");
  const [qualification, onTextChangee_quali] = useState<string>("");
  const [skills, onSkillsAdd] = useState<any[]>([]);
  const [creeds, setCreeds] = useState(init);
  const { postAddJob, get_student_all_courses } = protectedApiService();
  const { getAllCourses } = commonApiService();
  const { allCourses } = globalDataStore();
  const [image_upload, setimage_upload] = useState<any>(null);
  const [count_limit, setcount_limit] = useState(0);
  const [edu, setedu] = useState("");
  const onValueChange = (val: any) => {
   // console.log({ ...creeds, ...val });
    setCreeds({ ...creeds, ...val });
  };


const company_logo_add = (e)=>{
 // console.log(e.target.files[0]);
  setimage_upload(e.target.files[0]);
}


const education_length = (e)=>{
var cont = e.target.value;
setcount_limit(cont.length);
setedu(cont);
if(cont.length>100){
  edu.slice(0,100);
  setedu(edu);
  toast.error("Limit exceed");
}else{
  
}
// console.log(edu);
}


  const onSubmit = async () => {
    let error = false;
    Object.keys(creeds).map((x: any) => {
      console.log(creeds[x]);
      if (creeds[x] == "") {
        toast.error("Error in " + x);
        error = true;
      }
    });
    if (!error) {
      // const res: any = await postAddJob({
      //   ...creeds,
      //   job_description: job_description,
      //   skills: JSON.stringify(skills),
      // });



      // const res: any = await postAddJob({
      //   ...creeds,
      //   job_description: job_description,
      //   skills: JSON.stringify(skills),
      // });

if(image_upload==null){
  toast.error("Please upload company logo");
}else{

  console.log(creeds);
      var fd = new FormData();

      fd.append("title",creeds.title);
      fd.append("education",edu);
      fd.append("location",creeds.location);
      fd.append("company",creeds.company);
      fd.append("company_logo",image_upload, image_upload.name);
      fd.append("experience",creeds.experience);
//      fd.append("job_role",creeds.job_role);
      fd.append("terms_condition", creeds.terms_condition);
      fd.append("course_id",creeds.course_id);
      //fd.append("qualification", qualification);
      fd.append("duration", creeds.duration);
      fd.append("package", creeds.package);
      fd.append("job_description", job_description);
      fd.append("skills", JSON.stringify(skills));
      
      const res: any = await postAddJob(fd);

      if (res == 1) {
        toast.success("Job Added");
        setCreeds(init);
        onSkillsAdd([]);
        onTextChangee("");
        creeds['job_description'] = "";
      } else {
        toast.error("Adding failed!");
      }
    }
    } else {
      toast.error("Some fields have error!");
    }
    
  };
  const [course, setCourse] = useState<any>(null);
  const getCourse = async () => {
    const res: any = await getAllCourses();
    setCourse(res);
  };
  useEffect(() => {
    if (allCourses) {
      setCourse(allCourses);
    } else {
      getCourse();
    }
  }, []);
  return (
    <>
      <div className=" mt-3">
        <h5>New Job Details</h5>
        <div className="card shadow mt-3 p-4">
          <div className="row mx-3">
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  id="title"
                  value={creeds.title}
                  aria-describedby="namelHelp"
                  placeholder="Title"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {course && (
              <div className="col-sm-6 ">
                <div className="mb-3">
                  <label htmlFor="course" className="form-label">
                    Related Course
                  </label>
                  <select
                    className="form-select"
                    name="course_id"
                    id="course_id"
                    defaultValue={creeds.course_id}
                    required
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="default" selected>
                      Select Course
                    </option>
                    {course.map((x: any) => (
                      <option value={x.course_id}>{x.page_name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="course" className="form-label">
                  Job Duration
                </label>
                <select
                  className="form-select"
                  name="duration"
                  id="duration"
                  defaultValue={creeds.duration}
                  required
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option value="default" disabled selected hidden>
                    Select Course
                  </option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  id="company"
                  value={creeds.company}
                  aria-describedby="namelHelp"
                  placeholder="example"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>


 <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Company Logo
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="company_logo"
                  id="company_logo"
                  accept="image/*"
                  
                  aria-describedby="namelHelp"
                  placeholder="example"
                  onChange={(e)=>{company_logo_add(e)}}
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  // onChange={(e) =>
                  //   onValueChange({
                  //     [e.target.name]: e.target.value,
                  //   })
                  // }
                />
              </div>
            </div>


            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="contact_no" className="form-label">
                  Package
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="package"
                  id="package"
                  value={creeds.package}
                  aria-describedby="namelHelp"
                  placeholder="10 - 15 L / Not disclosable"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="contact_no" className="form-label">
                  Experience
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="experience"
                  id="experience"
                  value={creeds.experience}
                  aria-describedby="namelHelp"
                  placeholder="Years"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Education
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="education"
                  id="education"
                  
                  aria-describedby="namelHelp"
                  placeholder="Education Details"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  // onChange={(e) =>
                  //   onValueChange({
                  //     [e.target.name]: e.target.value,
                  //   })
                  // }
                  
                  onKeyUp={(e) =>
                    education_length(e)
                  }
                  required
                />
                <span style={{fontSize: "13px !important",position:"absolute", maxWidth: "25rem", textAlign:"right", width: "100%"}}>{count_limit}/100</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  id="location"
                  value={creeds.location}
                  aria-describedby="namelHelp"
                  placeholder="14H Road, example "
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/* <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Job Role
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="job_role"
                  id="job_role"
                  value={creeds.job_role}
                  aria-describedby="namelHelp"
                  placeholder="Senior Programmer II"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Terms and Conditions
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="terms_condition"
                  id="terms_condition"
                  value={creeds.terms_condition}
                  aria-describedby="namelHelp"
                  placeholder="T&C "
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3 ">
                <p className="form-label">Skills</p>
                <Chips
                  width={"100%"}
                  separator=","
                  value={skills}
                  onChange={(e) => onSkillsAdd(e.value)}
                />
              </div>
            </div>


            {/* <div className="col-sm-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Qualification
                </label>
                <Editor
                  style={{ height: "180px" }}
                  value={creeds.qualification}
                  onTextChange={(e: any) => onTextChangee_quali(e.htmlValue)}
                />
              </div>
            </div> */}


            <div className="col-sm-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Job Description
                </label>
                <Editor
                  style={{ height: "180px" }}
                  value={creeds.job_description}
                  onTextChange={(e: any) => onTextChangee(e.htmlValue)}
                />
              </div>
            </div>
          </div>
          <div className="flex-start p-3 mx-3">
            <button onClick={onSubmit} className="btn btn-primary">
              Add New Job
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
