import { useLocation } from "react-router-dom";
import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
export default function CoursewiseQuestions(){
    const [allData, setallData] = useState([]);
    const location = useLocation();
    const profile = location.state;
    const { get_category_questions, mcq_question_add, delete_question_id } = protectedApiService();
    const [deleteModalShow, setDeleteModalShow] = useState(false);


    const init = {
        course_id: profile.course_id,
        question: "",
        a: "",
        b: "",
        c: "",
        d: "",
        answer:""
      };
      const [creeds, setCreeds] = useState(init);

      const onValueChange = (val: any) => {
        // console.log(val);
        setCreeds({ ...creeds, ...val });
       // console.log(val);
        // console.log(register);
      };


    const tablesStructure: Columns[] = [
        {
          data_name: "question",
          header: "Question",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "a",
            header: "Option A",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "b",
            header: "Option B",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "c",
            header: "Option C",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },

          {
            data_name: "d",
            header: "Option d",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "answer",
            header: "Answer",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        // {
        //   data_name: "duration",
        //   header: "Duration",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //   data_name: "description",
        //   header: "Course Description",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //     data_name: "classes",
        //     header: "Total Classes",
        //     sortable: true,
        //     dataFilter: (data: any, key: any) =>
        //       (
        //         <>
        //           <span className="limited-char">{data[key]}</span>
        //         </>
        //       ) || <></>,
        //   },
          {
            data_name: "operation",
            header: "Operation",
            sortable: false,
            dataFilter: (data: any, key: any) => {
              return (
                <>

                    <button className="btn btn-danger" onClick={()=>delete_questions(data)}>Delete</button>

                 {/* <Link to="/Home/mcq-quiz/Course wise questions" state={data}>
                     <Button
                      icon="pi pi-file-edit"
                      className="p-button-rounded p-button-primary p-button-outlined"
                      aria-label="Delete"
                    /> 
                    <button className="btn btn-primary">Course wise questions</button>
                  </Link>*/}
                  
                </>
              );
            },
          },
      ];


useEffect(()=>{
    get_questions();
},[])


const get_questions = async()=>{
    const data = await get_category_questions(profile.course_id);
    //console.log(data);
    setallData(data);
}


const onSubmit = async()=>{
console.log(creeds);
const res = await mcq_question_add(creeds);
if(res.status==1){
  toast.success(res.msg);
  const init = {
    course_id: profile.course_id,
    question: "",
    a: "",
    b: "",
    c: "",
    d: "",
    answer:""
  };

  setCreeds(init);
}else{
  res.error(res.msg);
}
}


const close_btn_click = ()=>{
  get_questions();
  setDeleteModalShow(false)
}


const delete_questions = async(data)=>{
  const d = window.confirm("Are you sure to delete?");
  if(d==true){
const res =  await delete_question_id(data.mcq_question_id);
if(res==1){
  toast.error("Question deleted successfully");
  get_questions();
}
  }
  
}


    return(
        <>
                      <div className="das-exs ">
          <div className="flex-end mx-4">
          <button className="btn btn-success" onClick={()=>{setDeleteModalShow(true)}}>Add Question</button>
          </div>
        </div>
        <PrimeDataTable
          data={allData || []}
          structure={tablesStructure}
          title={"Questions"}
          onRefresh={get_questions}
        />


<Modal
            fullscreen={true}
            aria-labelledby="example-custom-modal-styling-title"
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
               Add Question of {profile.course_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
         
            <div className="container view-student" style={{maxWidth:"95%"}}>
            <div className="main-body">
              <div className="row gutters-sm">
             
              <div className="col-md-3">
                          <h6>Question</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="question"
                            id="course_name"
                            value={creeds.question}
                            required
                         
                            aria-describedby="namelHelp"
                            placeholder="Add Question"
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                             onChange={(e) =>
                              onValueChange({
                                [e.target.name]: e.target.value,
                              })
                            }
                           
                          />
                        </div>

                        <div className="col-md-2">
                          <h6>Option A</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="a"
                            id="a"
                            required
                            value={creeds.a}
                         
                            aria-describedby="namelHelp"
                            placeholder="Option"
                            onChange={(e) =>
                                onValueChange({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }
                           
                          />
                        </div>

                        <div className="col-md-2">
                          <h6>Option B</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="b"
                            id="b"
                            required
                            value={creeds.b}
                         
                            aria-describedby="namelHelp"
                            placeholder="Option"

                            onChange={(e) =>
                                onValueChange({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }
                           
                          />
                        </div>

                        <div className="col-md-2">
                          <h6>Option C</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="c"
                            id="c"
                            required
                            value={creeds.c}
                         
                            aria-describedby="namelHelp"
                            placeholder="Option"
                            onChange={(e) =>
                                onValueChange({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }
                           
                          />
                        </div>

                        <div className="col-md-2">
                          <h6>Option D</h6>
                          <input
                            type="text"
                            className="form-control"
                            name="d"
                            id="d"
                            required
                            value={creeds.d}
                            aria-describedby="namelHelp"
                            placeholder="Option"
                            onChange={(e) =>
                                onValueChange({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                            //  onChange={(e) =>
                            //   onValueChange({
                            //     [e.target.name]: e.target.value,
                            //   })
                            // }
                           
                          />
                        </div>


                        <div className="col-md-1">
                          <h6>Result</h6>
                          <select className="form-control" name="answer" required  onChange={(e) =>
                                onValueChange({
                                  [e.target.name]: e.target.value,
                                })
                              }>
                            <option value="">Select One</option>
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                          </select>
                        </div>

              </div>
              <div className="row">
                        <div className="col-sm-12" style={{marginTop:"20px"}}>
                          <button
                            onClick={() => onSubmit()}
                            className="btn btn-info"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
            </div>
          </div>

            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => close_btn_click()}
                className="btn btn-info btn-sm"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

        </>
        
    )
}