import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useEffect, useState } from "react";
import studentSkillUpApi from "../../../../../services/_student_skillup_api";

import studentGlobalDataStore from "../../../../../store/_global_studentData";
import Loader from "../../../../../common/loader";

export default function AllResources() {
  const { getCourseResource } = studentSkillUpApi();
  const { liveClass, setLiveClass } = studentGlobalDataStore();
  const [data, setData] = useState<any>(null);
  console.log(liveClass);
  useEffect(() => {
    if (localStorage.getItem("live-class")) {
      setLiveClass(JSON.parse(localStorage.getItem("live-class") || ""));
    }
    getData();
  }, []);
  const getData = async () => {
    const res: any = await getCourseResource(liveClass.course);
    console.log(res);
    setData(res);
  };
  return (
    <>
      <>
        {!data && <Loader />}
        {data && (
          <>
            <div className="card  enrolled p-4">
              <h5 className="heading">All Resources</h5>
              <div className="row">
                {data.map((x: any) => (
                  <div className="col-sm-3">
                    <div className="card assignment-card p-3">
                      <div className="flex-center flex-column">
                        <img src="/assets/student/notes.png" alt="" />
                        <h5 className="heading text-center">
                          {x.title || "Penetration Testing"}
                        </h5>
                      </div>
                      <a href={x.resource_link} target="_blank">
                        <button className="btn btn-sm mt-2 btn-primary btn-wide">
                          Download
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}
