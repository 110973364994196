import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { CgRuler } from "react-icons/cg";
import { VscBook } from "react-icons/vsc";
import { AiOutlineRadiusSetting } from "react-icons/ai";
import { TbMessage2, TbEye, TbFileImport } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Columns } from "../interfaces/_common";
import { BiRefresh } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import protectedApiService from "../services/_protected_api";
import { Button } from "primereact/button";
import Loader from "./loader";
import Loader2 from "./loader2";
import Loader3 from "./loader3";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import userState from "../store/_userState";

export default function PrimeDataTable({
  structure,
  data = null,
  isForStudent,
  title,
  onRefresh,
  noSearch,
  view,
  remove,
  viewclass,
  message,
  timeline,
  setclass,
  options,
  importable,
  filterDropdown,
  onSelect,
  noChecks,
  skillup,
  newdropdown
}: any) {
  const location = useLocation();
  // console.log(location);
  let locationPath: any = location.pathname.replaceAll("%20", " ").split("/");
  let final_path = "";
  locationPath.forEach((element: any, index: any) => {
    if (locationPath.length - 1 != index && element) {
      final_path += "/" + element;
    }
  });

  ////********************************** */
  const showMenu = (cls: any) => {
    removeMenu();
    let menu: any = (document.getElementById(cls) as HTMLElement) || null;
    console.log(cls);
    menu.style.display = "block";
  };
  const removeMenu = () => {
    let menues: any =
      (document.getElementsByClassName(
        "menu"
      ) as HTMLCollectionOf<HTMLElement>) || null;
    Object.keys(menues).map((x: any) => {
      menues[x].style.display = "none";
    });
  };
  const op = useRef(null);
  const { deleteUser, usermovetojunk, usermovetocollege } = protectedApiService();
  const [loading, setLoading] = useState("Loading....");
  const [changeableData, setChangeableData] = useState<any>(null);
  useEffect(() => {
   // console.log(data);
    if (data && data?.length) {
      setChangeableData(data);
    }
    setTimeout(() => {
      setLoading("No Data Available");
    }, 4500);
  }, [data]);

  const empt: any[] = [];
  const [selectedData, setSelectedData] = useState<any[]>([]);

  //********************* */
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInputTooltip, setPageInputTooltip] = useState(
    "Press 'Enter' key to go to this page."
  );
  const onPageInputChange = (event: any) => {
    setCurrentPage(event.target.value);
  };
  const onSearchValueChange = () => {};

  //***************** */
  const onDelete = async (creeds: any) => {
    const res: any = await deleteUser(creeds.user_id);
    if (res) {
      toast.success("Deleted");
      onRefresh();
    }
    
  };

const movetojunk = async (creeds:any)=>{
  var conf = window.confirm("Are you sure?");
  if(conf==true){
  const res = await usermovetojunk(creeds.user_id)
  if (res) {
    toast.success("User moved to junk");
    onRefresh();
  }
}
}

const movetocollege = async (creeds:any)=>{
  var conf = window.confirm("Are you sure?");
  if(conf==true){
  const res = await usermovetocollege(creeds.user_id)
  if (res) {
    toast.success("User moved to college student");
    onRefresh();
  }
}
}


  const onPageInputKeyDown = (event: any, options: any) => {
    if (event.key === "Enter") {
      const page = currentPage;
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(
          `Value must be between 1 and ${options.totalPages}.`
        );
      } else {
        const first = currentPage ? options.rows * (page - 1) : 0;

        setPageInputTooltip("Press 'Enter' key to go to this page.");
      }
    }
  };
  const paginatorLeft = (
    <button className="btn btn-warning">
      <BiRefresh color={"white"} size={28} />
    </button>
  );

  const paginatorRight = (
    <div className="table-body-footer">
      <div className="flex-between">
        {selectedData?.length && isForStudent ? (
          <>
            {" "}
            <div className="options flex-end">
              {viewclass && (
                <button className="outlined-btn flex-start-center mx-1">
                  <VscBook size={20} />
                  <span className="mx-1">View Class</span>
                </button>
              )}

              {setclass && (
                <button className="outlined-btn flex-start-center mx-1">
                  <AiOutlineRadiusSetting size={20} />
                  <span className="mx-1">Set Class</span>
                </button>
              )}
              {timeline && !(selectedData.length > 1) && (
                <Link to={`/Home/Timeline`} state={selectedData}>
                  <button className="outlined-btn flex-start-center mx-1">
                    <CgRuler size={20} />
                    <span className="mx-1">Timeline</span>
                  </button>
                </Link>
              )}
              {message && (
                <Link to={`/Home/Message`} state={selectedData}>
                  <button className="outlined-btn flex-start-center mx-1">
                    <TbMessage2 size={20} />
                    <span className="mx-1">Message</span>
                  </button>
                </Link>
              )}

              {view && !(selectedData.length > 1) && (
                <Link to={`/Home/Students/View Class`} state={selectedData}>
                  <button className="outlined-btn flex-start-center mx-1">
                    <TbEye size={20} />
                    <span className="mx-1">View Classes</span>
                  </button>
                </Link>
              )}

              {skillup && !(selectedData.length > 1) && (
                <Link to={`/Home/reports/SkillUp Status/See Status`} state={selectedData}>
                  <button className="outlined-btn flex-start-center mx-1">
                    <TbEye size={20} />
                    <span className="mx-1">SkillUp Status</span>
                  </button>
                </Link>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {importable && (
          <button
            onClick={() => exportCSV(false)}
            className="outlined-btn flex-start-center mx-1"
          >
            <TbFileImport size={20} />
            <span className="mx-1">Import</span>
          </button>
        )}
      </div>
    </div>
  );
  //****************** *//
  const tableTemplate: any = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options: any) => {
      return (
        <Button
          icon="pi pi-angle-left"
          onClick={options.onClick}
          className="p-button-rounded p-btn-info p-button-outlined   m-1"
          disabled={options.disabled}
        />
      );
    },
    NextPageLink: (options: any) => {
      return (
        <Button
          icon="pi pi-angle-right"
          onClick={options.onClick}
          className="p-button-rounded p-btn-info p-button-outlined   m-1"
          disabled={options.disabled}
        />
      );
    },

    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <>
          <Button
            label={options.page + 1}
            onClick={options.onClick}
            className="p-button-rounded p-btn-info p-button-outlined   m-1"
          />
        </>
      );
    },

    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "All", value: options.totalRecords },
      ];

      return (
        <>
          {/* <DropdownButton
            key={"primary"}
            id={`dropdown-variants-primary`}
            variant={"primary"}
            title={"10"}
            defaultValue={options.value}
            onChange={options.page + 1}
          >
            {dropdownOptions.map((x) => (
              <Dropdown.Item eventKey={x.value} value={x.value}>
                {x.label}
              </Dropdown.Item>
            ))}
          </DropdownButton> */}
        </>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onSearch = (search: any) => {
    let keys: any = Object.keys(data[0]);
    // console.log(keys);
    let searchedData: any[] = data.filter((d: any) => {
      let checker: any[] = [];
      keys.map((k: any) => {
        var strRegExPattern = `${search}.*`;
        if (d[k]) {
          if (typeof d[k] != "string") {
            if (
              d[k]
                .toString()
                .toLowerCase()
                .match(new RegExp(strRegExPattern, "g"))
            )
              checker.push(1);
          } else {
            if (
              new RegExp(strRegExPattern.toLocaleLowerCase(), "g").test(
                d[k].toLowerCase()
              )
            )
              checker.push(1);
          }
        }
        return 0;
      });
      if (checker.length) {
        return true;
      } else {
        return false;
      }
    });
    setChangeableData(searchedData);
  };
  const dt = useRef<any>(null);
  const exportCSV = (selectionOnly: any) => {
    dt.current.exportCSV({ selectionOnly });
  };
  const header: any = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file"
        onClick={() => exportCSV(false)}
        className="mr-2"
        data-pr-tooltip="CSV"
      />
    </div>
  );
  return (
    <>
      {data ? (
        <>
          {" "}
          {changeableData ? (
            <>
              {" "}
              <div className="table">
                <div className="table-main mx-auto">
                  <div className="table-header">
                    <div className="row">
                      <div className="heading col-sm-4">
                        {title ? title : "All Details"}
                      </div>
                      {!noSearch && data && (
                        <div className="col-sm-8">
                          <div className="search d-flex">
                            {filterDropdown && (
                              <div className="filter p-1">
                                <Button
                                  style={{
                                    height: "100%",
                                    width: "2.5rem",
                                  }}
                                  icon="pi pi-filter"
                                  className="p-button-rounded "
                                  aria-label="Filter"
                                  onClick={(e) => op.current.toggle(e)}
                                />
                                <OverlayPanel ref={op}>
                                  
                                  
                                  {filterDropdown &&
                                    data &&
                                    filterDropdown.map((fil: any) => (
                                     
                                      <>
                                      
                                     
                                        {fil?.type === "date" ? (
                                          <DateFilterDropdown
                                            allData={data}
                                            filterField={fil.filter}
                                            setChangeableData={
                                              setChangeableData
                                            }
                                            header={fil.header}
                                          />
                                        ): fil?.type==='job_search' ? (
                                          <FilterDropdown
                                            allData={data}
                                            filterField={fil.filter}
                                            setChangeableData={
                                              setChangeableData
                                            }
                                            header={fil.header}
                                          />
                                        ) :(
                                          <FilterDropdown
                                            allData={data}
                                            filterField={fil.filter}
                                            setChangeableData={
                                              setChangeableData
                                            }
                                            header={fil.header}
                                          />
                                        )
                                        
                                          }
                                      </>
                                    ))}
                                </OverlayPanel>
                              </div>
                            )}




{newdropdown && (
                              <div className="filter p-1">
                                <Button
                                  style={{
                                    height: "100%",
                                    width: "2.5rem",
                                  }}
                                  icon="pi pi-filter"
                                  className="p-button-rounded "
                                  aria-label="Filter"
                                  onClick={(e) => op.current.toggle(e)}
                                />



                                <OverlayPanel ref={op}>
                                  <NewcustFilter allData={data} setChangeableData={
                                              setChangeableData
                                            } />
                              

                                </OverlayPanel>
                              </div>
                            )}

                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="search"
                              onChange={(e) => {
                                onSearch(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {changeableData.length ? (
                    <div className="table-body mx-auto">
                      <DataTable
                        ref={dt}
                        className="live-session"
                        value={changeableData || null}
                        sortMode="multiple"
                        responsiveLayout="scroll"
                        paginator
                        paginatorTemplate={tableTemplate}
                        // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        
                        paginatorLeft={paginatorLeft}
                        paginatorRight={paginatorRight}
                        selection={selectedData}
                        onSelectionChange={(e) => setSelectedData(e.value)}
                      >
                        {!noChecks && (
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3em" }}
                          ></Column>
                        )}
                        {options && (
                          <Column
                            headerStyle={{ width: "3em" }}
                            style={{
                              position: "relative",
                            }}
                            body={(e) => (
                              <>
                                {e?.user_id && (
                                  <div className="table-menu">
                                    <button className="tbl-btn">
                                      <img
                                        className="press"
                                        onClick={() =>
                                          showMenu(`menuid${e.user_id}`)
                                        }
                                        src="/assets/svg/more.svg"
                                        alt="..."
                                      />
                                    </button>
                                    <div
                                      id={`menuid${e.user_id}`}
                                      className="menu"
                                      onMouseLeave={removeMenu}
                                    >
                                      <Link to="/Home/Profile" state={e}>
                                        <button className="flex-start text-dark option">
                                          <TbEye size={20} />
                                          <span className="mx-3">View</span>
                                        </button>
                                      </Link>
                                      <Link to="/Home/Edit Profile" state={e}>
                                        <button className="flex-start text-dark option">
                                          <img
                                            src="/assets/svg/edit.svg"
                                            alt=""
                                          />
                                          <span className="mx-3">Edit</span>
                                        </button>
                                      </Link>
                                      <button
                                        onClick={() => movetocollege(e)}
                                        className="flex-start text-dark option"
                                      >
                                        <img
                                          src="https://img.freepik.com/free-icon/man_318-881195.jpg?w=2000"
                                          alt=""
                                          style={{width:"20px", height:"20px"}}
                                        />
                                        <span className="mx-3">College</span>
                                      </button>

                                      <button
                                        onClick={() => movetojunk(e)}
                                        className="flex-start text-danger option"
                                      >
                                        <img
                                          src="/assets/svg/trash.svg"
                                          alt=""
                                        />
                                        <span className="mx-3">Junk</span>
                                      </button>
                                      <button
                                        onClick={() => onDelete(e)}
                                        className="flex-start text-danger option"
                                      >
                                        <img
                                          src="/assets/svg/trash.svg"
                                          alt=""
                                        />
                                        <span className="mx-3">Delete</span>
                                      </button>

                                     

                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          ></Column>
                        )}

                        {changeableData?.length &&
                          structure.map((key: Columns) => (
                            <Column
                              field={key.data_name}
                              header={key.header}
                              sortable={key.sortable}
                              body={(e) => {
                                return (
                                  <>
                                    <div className="flex-start">
                                      {key.dataFilter(e, key.data_name)}
                                    </div>
                                  </>
                                );
                              }}
                            ></Column>
                          ))}
                      </DataTable>
                    </div>
                  ) : (
                    <>
                      <Loader3 />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <Loader3 />
            </>
          )}
        </>
      ) : (
        <>
          <Loader2 />
        </>
      )}
    </>
  );
}
export const FilterDropdown = ({
  allData,
  filterField,
  setChangeableData,
  changeableData,
  header,
}: any) => {
  const [uniqueItems, setUniqueItems] = useState<any>(null);
  function groupBy(arr: any, property: any) {
    return arr.reduce(function (memo: any, x: any) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  const setRunningCourses = () => {
    var course_set: any = [];
    allData.forEach((element: any) => {
      course_set.push(element[filterField]);
    });
    let unique: any = [...new Set(course_set)];
    // let items = groupBy(allData, "course_name");
    console.log(unique);
    
    setUniqueItems(unique);
  };
  const onValueChange = (value: any) => {
    if (value == "all") {
      setChangeableData(allData);
    } else {
      const newValue: any[] = [];
      allData.map((data: any) => {
        if (data[filterField] == value) {
          newValue.push(data);
        }
      });
      console.log(newValue);
      setChangeableData(newValue);
    }
  };
  useEffect(() => {
    setRunningCourses();
  }, [allData]);
  return (
    <>
      <div className="mb-3">
        <label htmlFor="course_mode" className="form-label">
          {header}
        </label>
        {uniqueItems && (
          <select
            className="form-select mr-2"
            name="course_mode"
            style={{ maxWidth: "23rem" }}
            id="course_mode"
            onChange={(e) => onValueChange(e.target.value)}
          >
            <option value="default" disabled selected hidden>
              Select {header || ""}
            </option>
            <option value="all">All</option>
            {uniqueItems &&
              uniqueItems.map((item: any) => (
                <>{item && <option value={item}>{item}</option>}</>
              ))}
          </select>
        )}
      </div>
    </>
  );
};
export const DateFilterDropdown = ({
  allData,
  filterField,
  setChangeableData,
  header,
}: any) => {
  const [range, setRange] = useState<any>({
    start: null,
    end: null,
    start_time: null,
    end_time: null,
  });
  function onFilter() {
    console.log(range);
    if (range.start && range.end && range.start && range.end_time) {
      let f_data: any = [];

      allData.map((x: any) => {
        // console.log(
        //   new Date(`${x.date}T${x.start_time}:00`),
        //   new Date(`${range.start}T${range.start_time}`),
        //   new Date(`${x.date}T${x.end_time}:00`),
        //   new Date(`${range.end}T${range.end_time}`)
        // );
        if (
          new Date(`${x.date}T${x.start_time}:00`).getTime() >=
            new Date(`${range.start}T${range.start_time}:00`).getTime() &&
          new Date(`${x.date}T${x.end_time}:00`).getTime() <=
            new Date(`${range.end}T${range.end_time}:00`).getTime()
        ) {
          // console.log(x);
          f_data.push(x);
        }
      });
      setChangeableData(f_data);
    }
  }

  const onValueChange = (e: any) => {
    setRange({
      ...range,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    onFilter();
  }, [range]);

  return (
    <>
      <div className="">
        <div className="d-flex">
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              Start Date
            </label>
            <input
              style={{
                maxWidth: "20rem",
                marginRight: "1rem",
              }}
              type="date"
              name="start"
              value={range.start}
              className="form-control"
              onChange={onValueChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              Start Time
            </label>
            <input
              style={{
                maxWidth: "20rem",
                marginRight: "1rem",
              }}
              type="time"
              name="start_time"
              value={range.start_time}
              className="form-control"
              onChange={onValueChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              End Date
            </label>
            <input
              style={{
                maxWidth: "20rem",
                marginRight: "1rem",
              }}
              type="date"
              name="end"
              value={range.end}
              className="form-control"
              onChange={onValueChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label">
              End Time
            </label>
            <input
              style={{
                maxWidth: "20rem",
                marginRight: "1rem",
              }}
              type="time"
              name="end_time"
              value={range.end_time}
              className="form-control"
              onChange={onValueChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const NewcustFilter = ({
  allData,
  setChangeableData,
  changeableData,
}: any) => {

const get_courses = (data)=>{
  const map = new Map();
  var akpk = [];

  for (const item of data) {
      if (!map.has(item.course_name)) {
          map.set(item.course_name, true);    // set any value to Map
          akpk.push(item);


      }
  }
var course_data = [];
 if(akpk.length>0){
  akpk.forEach(element => {
    course_data.push(element.course_name);
  });
 }

 return course_data;

}

const get_created_by_data = (data)=>{
  const map = new Map();
  var akpk = [];

  for (const item of data) {
      if (!map.has(item.mk_name)) {
          map.set(item.mk_name, true);    // set any value to Map
          akpk.push(item);


      }
  }
var marketing_name = [];
 if(akpk.length>0){
  akpk.forEach(element => {
    marketing_name.push(element.mk_name);
  });
 }

 return marketing_name;
}

const [course_name_cust, setcourse_name_cust] = useState([]);
const [course_name, setcourse_name] = useState("all");
const [received_by, setreceived_by] = useState("all");
const [start_date, setstart_date] = useState("");
const [end_date, setend_date] = useState("");
const [mk_name, setmk_name] = useState([]);
const [get_all_data, set_get_all_data] = useState([]);
const { user } = userState();
useEffect(()=>{
  set_get_all_data(allData);
//console.log(allData);
const dt = get_courses(allData);
const created_by = get_created_by_data(allData);
setmk_name(created_by);
setcourse_name_cust(dt);


},[allData])

const get_filter_data = ()=>{
 // var ald = allData;
//  console.log(ald);
  if(start_date==""){
       toast.error("Start Date cannot be empty");
  }else if(end_date==""){
       toast.error("End Date cannot be empty");
  }else{
     var res = get_date_filter(get_all_data,course_name,received_by,start_date,end_date);
    // console.log(res); 
    // console.log(course_name);
    // console.log(received_by);
    // console.log(start_date);
    // console.log(end_date);
if(res==undefined){
  setChangeableData([]);
}else{
  setChangeableData(res);
}
    

  }
}

const get_date_filter = (ald,course_name,received_by,start_date,end_date) =>{

  if(course_name=="all" && received_by=="all"){
    const res_data = [];
    ald.forEach(element => {
      if(element["date"]>=start_date && element["date"]<=end_date){
        res_data.push(element);
      }
    });

return res_data;

  }else if(course_name=="all" && received_by!="all"){
    const res_data = [];
    ald.forEach(element => {
      if(element["date"]>=start_date && element["date"]<=end_date && element["mk_name"]==received_by){
        res_data.push(element);
      }
    });

return res_data;
  }else if(course_name!="all" && received_by=="all"){
    const res_data = [];
    ald.forEach(element => {
      if(element["date"]>=start_date && element["date"]<=end_date && element["course_name"]==course_name){
        res_data.push(element);
      }
    });
    return res_data;
  }else{
    const res_data = [];
    ald.forEach(element => {
      if(element["date"]>=start_date && element["date"]<=end_date && element["course_name"]==course_name && element["mk_name"]==received_by){
        res_data.push(element);
      }
    });
    return res_data;
  }

}

  return (
    <>
      <div className="">
        
       
        <div className="mb-3">
        <label htmlFor="course_mode" className="form-label">
          Course Name
        </label>
      
          <select
            className="form-select mr-2"
            name="course_name"
            style={{ maxWidth: "23rem" }}
            id="course_name"
           onChange={(e)=>{setcourse_name(e.target.value)}}
          >
            <option value="default" disabled selected hidden>
              Select Course
            </option>
            <option value="all">All</option>
            {course_name_cust.map((co_name,index)=>{
              return(
                <>
                <option key={index} value={co_name}>{co_name}</option>
                </>
              )
            })}
          </select>
       
      </div>
{(()=>{
  if(user.role==1 || user.role==2){
    return(
      <>
            <div className="mb-3">
        <label htmlFor="course_mode" className="form-label">
          Received By
        </label>
      
          <select
            className="form-select mr-2"
            name="course_mode"
            style={{ maxWidth: "23rem" }}
            id="course_mode"
            onChange={(e)=>{setreceived_by(e.target.value)}}
          >
            <option value="default" disabled selected hidden>
              Select One
            </option>
            <option value="all">All</option>
            {mk_name.map((mk_nam,index)=>{
              return(
                <>
                <option key={index} value={mk_nam}>{mk_nam}</option>
                </>
              )
            })}
          </select>
       
      </div>
      </>
    )
  }
})()}


      <div className="mb-3">
        <label htmlFor="course_mode" className="form-label">
          Start Date
        </label>

        <input type="date" className="form-control mr-2" onChange={(e)=>{setstart_date(e.target.value)}} />
       
      </div>
      <div className="mb-3">
        <label htmlFor="course_mode" className="form-label">
          End Date
        </label>

        <input type="date" className="form-control mr-2"  onChange={(e)=>{setend_date(e.target.value)}} />
       
      </div>
      <button className="btn btn-primary" onClick={()=>{get_filter_data()}}>Filter</button>
       
      </div>
    </>
  );
};

