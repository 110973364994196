import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useCallback, useEffect, useState } from "react";
import commonApiService from "../../../../../services/_common_api";
import globalDataStore from "../../../../../store/_globalData";
import { FilterDropdown } from "../../../../../common/prime_data_table";
import Loading from "../../../../../common/loader";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/button";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";
import protectedStudentApiService from "../../../../../services/_protected_student_api";
import userState from "../../../../../store/_userState";
export default function AllCourseList() {
  const { getAllCourses } = commonApiService();
  const { allCourses, setAllCourses } = globalDataStore();
  const [changeableData, setChangeableData] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [pricing, setPricing] = useState<any>(null);
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userdetails, setUserDetails] = useState<any>();
  const { getPaymentDetails } = commonApiService();
  const [rzp1, setRzp1] = useState<any>(null);
  const { getStudentDetails } = protectedStudentApiService();
  const { user } = userState();
  const getDetails = async () => {
    const res: any = await getStudentDetails(user.user_id);
    // console.log({ ...res, ...user });
    setUserDetails({ ...res, ...user });
  };
  useEffect(() => {
    getDetails();
    if (!allCourses) {
      fetchCourses();
    } else {
      setChangeableData(allCourses);
    }
  }, []);
  const fetchCourses = useCallback(async () => {
    const res: any = await getAllCourses();
    setAllCourses(res);
    setChangeableData(res);
  }, [allCourses]);

  ///////////////////////////////////////////////
  const Razorpay = useRazorpay();
  const get_payment_details = async (response: any) => {
    var ar = {
      enquery_course_id: "course_id",
      payment_id: response.razorpay_payment_id,
      order_id: response.razorpay_order_id,
      payment_signature: response.razorpay_signature,
      payment_type: "razorpay",
    };
    var data = btoa(btoa(btoa(btoa(JSON.stringify(ar)))));
    //console.log(JSON.stringify(ar));
    const res: any = await getPaymentDetails(data);
    if (res == 1) {
      toast.success("Payment Successful");
    }
  };
  // const createOrder = (params: any) => {
  //   let order: any = {
  //     id: Math.random() * 5000,
  //     amount: params.price,
  //   };
  //   return order;
  // };

  const handlePayment = async (params: any) => {
    // const order: any = await createOrder(params);
    const options: any = {
      key: "rzp_live_iW00YJBkX51J2B", // Enter the Key ID generated from the Dashboard
      amount: params.price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: selectedCourse?.course_name,
      description: "Short description",
      image: "https://dataspaceacademy.com/images/LOGO.png",
      // order_id: Math.random() * 50000,
      handler: function (response: any) {
        get_payment_details(response); ////////////// Return Response.

        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
      },
      prefill: {
        name: userdetails.name,
        email: userdetails.username,
        contact: userdetails.contact_no,
      },
      notes: {
        address: "DataSpace Academy",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  return (
    <>
      <>
        {!allCourses && <Loading />}
        {allCourses && (
          <div className="container enrolled ">
            <h5 className="heading">All Courses</h5>
            <div className="d-flex justify-content-end my-2">
              <FilterDropdown
                allData={allCourses}
                filterField={"category"}
                setChangeableData={setChangeableData}
                // header={"Category"}
              />
            </div>
            <div className="row">
              {!changeableData && <Loading />}
              {changeableData &&
                changeableData.map((x: any) => (
                  <div className="col-sm-4 flex-center ">
                    <div className="card course-card shadow m-2  ">
                      <img
                        src={x.featured_image || "/assets/bg/register_bg.png"}
                        alt=""
                      />
                      <div className="details p-4">
                        <h5 className="heading">
                          {x.page_name || "Test Course"}
                        </h5>
                        <div className="sub">
                          <div className="flex-between">
                            <div className="left">
                              {/* <span className="text-gray">
                                Faculty : {x.faculty || "Test Faculty"}
                              </span> */}
                              <span className="text-gray">
                                Class Duration : {x.duration || "3"} Months
                              </span>
                            </div>
                            <div className="right">
                              <a
                                href={`https://dataspaceacademy.com/${x.slug}`}
                              >
                                <button className="btn  btn-sm btn-outline-primary  ">
                                  View Details
                                </button>
                              </a>
                            </div>
                          </div>
                          <div className="row  ">
                            <div className="col-sm-6 mt-2">
                              <button
                                onClick={() => {
                                  handleShow();
                                  setPricing(JSON.parse(x.plans));
                                  setSelectedCourse(x);
                                }}
                                className="btn btn-sm btn-primary btn-wide"
                              >
                                Enroll Now
                              </button>
                            </div>
                            <div className="col-sm-6 mt-2">
                              <Link to="Already Enrolled" state={x}>
                                <button className="btn btn-sm btn-primary btn-wide">
                                  Already Enrolled
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show && pricing}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row align-item-center">
            {pricing &&
              pricing.map((x: any) => (
                <div className="col-sm-4 flex-start-center">
                  <div className="plans-card shadow card mx-2 p-3">
                    <div className="flex-between">
                      <h4 className="text-warning">{x.plan_name}</h4>
                      <h5 className="text-success">{x.price} /-</h5>
                    </div>
                    <hr />
                    <p className="text-gray">Plan Description</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: x.other_data }}
                    ></div>
                    <hr />
                    {x.price == "0" ? (
                      <Button
                        label="Free Enroll Now"
                        className="btn  btn-success"
                      />
                    ) : (
                      <Button
                        onClick={() => handlePayment(x)}
                        label="Punches Now"
                        className="p-btn"
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
