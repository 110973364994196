import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";

export default function Quizquestion(){

    const { get_student_all_courses } = protectedApiService();

    const [allData, setallData] = useState([]);

   


    const tablesStructure: Columns[] = [
        {
          data_name: "course_name",
          header: "Course Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        // {
        //   data_name: "duration",
        //   header: "Duration",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //   data_name: "description",
        //   header: "Course Description",
        //   sortable: true,
        //   dataFilter: (data: any, key: any) =>
        //     (
        //       <>
        //         <span className="limited-char">{data[key]}</span>
        //       </>
        //     ) || <></>,
        // },
        // {
        //     data_name: "classes",
        //     header: "Total Classes",
        //     sortable: true,
        //     dataFilter: (data: any, key: any) =>
        //       (
        //         <>
        //           <span className="limited-char">{data[key]}</span>
        //         </>
        //       ) || <></>,
        //   },
          {
            data_name: "operation",
            header: "Operation",
            sortable: false,
            dataFilter: (data: any, key: any) => {
              return (
                <>
                  <Link to="/Home/mcq-quiz/Course wise questions" state={data}>
                    {/* <Button
                      icon="pi pi-file-edit"
                      className="p-button-rounded p-button-primary p-button-outlined"
                      aria-label="Delete"
                    /> */}
                    <button className="btn btn-primary">Course wise questions</button>
                  </Link>
                  
                </>
              );
            },
          },
      ];


useEffect(()=>{
    get_courses();
},[])


const get_courses = async()=>{
    const courses = await get_student_all_courses();
    //console.log(courses);
    setallData(courses);
}


    return(
        <>
              <div className="das-exs ">
          <div className="flex-end mx-4">
           
          </div>
        </div>
        <PrimeDataTable
          data={allData || []}
          structure={tablesStructure}
          title={"All Courses for Quiz"}
          onRefresh={get_courses}
        />





        </>




    )
}