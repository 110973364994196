import { useEffect, useState, useCallback } from "react";
import studentGlobalDataStore from "../../../../../store/_global_studentData";
import SelflearningModuleVideoPlayer from "./selflearning_video_player";
import { GiPadlock, GiPadlockOpen } from "react-icons/gi";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import studentSkillUpApi from "../../../../../services/_student_skillup_api";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RxDoubleArrowLeft } from "react-icons/rx";
import $ from "jquery";
import Loader from "../../../../../common/loader";
import Loader2 from "../../../../../common/loader3";
import Loader3 from "../../../../../common/loader3";

export default function SelflearningVideoModuleDashboard() {
  const { skillUpModule, setSkillUpModule } = studentGlobalDataStore();
  const { getCourseModules, getCourseProgress, selflearning_getCourseModules } =
    studentSkillUpApi();
  const [modules, setModules] = useState<any>(null);
  const [progress, setProgress] = useState<any>(null);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     //live-class

  //     const class_det = localStorage.getItem("storage_course_id");
  //     console.log(class_det);

  //     // if (localStorage.getItem("skill-up")) {
  //     //   setSkillUpModule(JSON.parse(localStorage.getItem("skill-up") || ""));
  //     // }
  //   }, []);

  //   useEffect(() => {
  //     if (skillUpModule?.course_id) getData();
  //   }, [skillUpModule]);

  useEffect(() => {
    getData();
   
  }, [skillUpModule]);

  const [playable, setPlayable] = useState<any>(null);
  const getData = async () => {
    const class_det = localStorage.getItem("storage_course_id");
    const res1: any = await selflearning_getCourseModules(class_det);
    console.log(res1);
    // console.log("allmodukes", res1.data);
    setModules(res1.data);
    if (res1.data && res1.data.length) {
      setPlayable({ ...res1.data[0], index: 0 });
      const res2: any = await getCourseProgress();
      // console.log(
      //   res2,
      //   res2.data.filter((x: any) => x.course_id == class_det)
      // );
      setProgress(res2.data.filter((x: any) => x.course_id == class_det));
    }
  };
  const onRefresh = async () => {
    const res1: any = await getCourseModules(skillUpModule.course_id);
    console.log(res1.data);
    setModules(res1.data);
  };
  //
  const onNext = () => {
    if (playable.index < modules.length)
      setPlayable({
        ...modules[playable.index + 1],
        index: playable.index + 1,
      });
  };
  return (
    <>
      <>
        {modules ? (
          <>
            {modules && modules?.length ? (
              <>
                {progress ? (
                  <div className="skillup-dashboard container">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="frame">
                          <SelflearningModuleVideoPlayer
                            onRefresh={onRefresh}
                            playNext={onNext}
                            playable={playable}
                          />
                        </div>
                        <hr />
                        <h5 className="header">Description: </h5>
                        <div className="row mx-2">
                          <div
                            className="col-sm"
                            dangerouslySetInnerHTML={{
                              __html: playable.description,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div id="side-bar" className="side-panel p-3">
                          <div className="switch flex-center">
                            <RxDoubleArrowLeft color={"gray"} size={30} />
                          </div>
                          {/* <h5 className="heading">Course Details: </h5> */}
                          <div className="total d-flex align-items-center justify-content-start ">
                            <div style={{ width: 60, height: 60 }}>
                              {progress && (
                                <CircularProgressbar
                                  value={progress.completed_percent || 0}
                                  text={`${progress.completed_percent || 0}%`}
                                  strokeWidth={10}
                                  styles={buildStyles({
                                    // Rotation of path and trail, in number of turns (0-1)
                                    rotation: 0.25,

                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: "butt",

                                    // Text size
                                    textSize: "28px",

                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 0.5,

                                    // Can specify path transition in more detail, or remove it entirely
                                    // pathTransition: 'none',

                                    // Colors
                                    pathColor: `#2BBB10`,
                                    textColor: "#2BBB10",
                                    trailColor: "#f6fff5",
                                    backgroundColor: "#f6fff5",
                                  })}
                                />
                              )}
                            </div>
                            <div>
                              <h5 className="header">
                                
                                {skillUpModule ?(<>
                                  {skillUpModule.course_name}
                                </>):(<></>)}
                                <br />
                                <span style={{ fontSize: "13px" }}>
                                  Course Completed
                                </span>
                              </h5>
                            </div>
                          </div>
                          <hr />
                          <div className="modules">
                            <span className="p-2">Course Modules:</span>
                            {modules &&
                              modules.map((x: any, index: any) => (
                                <div
                                  className={`items flex-between ${
                                    playable.title == x.title ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    setPlayable({ ...x, index: index })
                                  }
                                >
                                  {x.video_completed > 0 ? (
                                    <GiPadlockOpen
                                      color={"#2BBB10"}
                                      size={27}
                                    />
                                  ) : (
                                    <GiPadlock color={"#B4B4B4"} size={27} />
                                  )}

                                  <div className="details align-items-start flex-column">
                                    <div className="mb-auto  ">
                                      <span className="name">
                                        {(() => {
                                          if (x.description != null) {
                                            return (
                                              <>
                                                {/* <div
                              className="heading "
                              dangerouslySetInnerHTML={{
                                __html: x.description
                                  .replaceAll("<p>", "")
                                  .replaceAll("</p>", ""),
                              }}
                            ></div> */}
                                              </>
                                            );
                                          }
                                        })()}
                                      </span>
                                    </div>
                                    <div className=" mt-1">{x.title}</div>
                                    <div className=" time">
                                      <span>
                                        {Math.floor(x.video_duration / 60)}:
                                        {Math.floor(x.video_duration % 60)} mins
                                      </span>
                                    </div>
                                  </div>
                                  <div className="progress flex-center">
                                    <ProgressBar
                                      variant="success"
                                      now={x.completed_percent}
                                      label={`${Math.floor(
                                        x.completed_percent
                                      )}%`}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <Loader />
                  </>
                )}
              </>
            ) : (
              <>
                <Loader2 />
              </>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}{" "}
      </>
    </>
  );
}
