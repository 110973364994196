import { useEffect, useState } from "react";
import { Editor } from "primereact/editor";
import { useLocation, useNavigate } from "react-router-dom";
import protectedApiService from "../../../services/_protected_api";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
export default function SendMessage({}) {
  const location = useLocation();
  const students = location.state;
  const navigate = useNavigate();
  const [creeds, setCreeds] = useState("");
  const [messages, setMessages] = useState<any>();
  const onValueChange = (val: any) => {
    // console.log(val);
    setCreeds(val);
    console.log(val);
    // console.log(register);
  };
  const { postAddMessage, getStudentMessages } = protectedApiService();
  const onSubmit = () => {
    students.map(async (s: any) => {
      const res: any = await postAddMessage({
        user_id: s.user_id,
        message: creeds,
      });
    });
    toast.success("Message sent successfully");
    getMessages();
  };

  const getMessages = async () => {
    const res: any = await getStudentMessages(students[0].user_id);
    setMessages(res);
  };
  useEffect(() => {
    getMessages();
  }, []);
  return (
    <>
      <div className="container">
        {/* <h5 className="heading">Message</h5> */}
        <div className=" border-sm row">
          <div className="col-sm-6">
            <Editor
              style={{ height: "63vh" }}
              value={creeds}
              onTextChange={(e: any) => onValueChange(e.htmlValue)}
            />
          </div>
          <div className="col-sm-6">
            {" "}
            <div className="content">
              <div className="flex-between">
                <div className="flex-center">
                  <p className="text-capitalize my-auto">
                    Send Message to
                    <span className="mx-2">
                      {students.map((s: any, index: any, { length }: any) => (
                        <>
                          {s.name} {index + 1 === length ? "" : ", "}
                        </>
                      ))}
                    </span>
                  </p>
                </div>
                <div className="flex-start mt-3">
                  <Button
                    icon="pi pi-send"
                    label="Send"
                    className="p-button-rounded p-button-outlined"
                    aria-label="Filter"
                    onClick={onSubmit}
                  />

<button onClick={() => navigate(-1)} className="btn btn-danger" style={{color:"white",marginLeft:"10px"}}>
            {" "}
            Back
          </button>
                </div>
              </div>

              <hr />

              <div
                style={{ height: "65vh", overflow: "auto" }}
                dangerouslySetInnerHTML={{ __html: creeds }}
                className="p-2 editor mb-3"
              ></div>
            </div>
          </div>
        </div>
        <div className="old-messages mx-2">
          <h6 className="heading">Old Message</h6>
          <hr />
          {messages &&
            messages.map((m) => {
              return (
                <>
                  <div
                    style={{ height: "65vh", overflow: "auto" }}
                    dangerouslySetInnerHTML={{ __html: m.message }}
                    className="p-2 editor mb-3"
                  ></div>
                  <hr />
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}
