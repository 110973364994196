import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import { RiDashboardFill, RiNewspaperLine } from "react-icons/ri";
import PaymentOutlet from "../pages/home/marketing/outlet_payment";
import { IoMdWallet } from "react-icons/io";
import Viewallpayment from "../pages/home/admin/payment_gateway/view_all_payments";
import Accountuserdashboard from "../pages/home/account_user";

export default function Accountuser() {
  const routes: any[] = [
    {
      route: "Base",
      path: "",
      element: <Navigate to="Dashboard" />,
      children: [],
      shortcut: true,
    },
    {
      route: "Dashboard",
      path: "Dashboard",
      element: <Accountuserdashboard />,
    //element: <AdminDashboard />,
      icon: <RiDashboardFill />,
      children: [],
      shortcut: true,
    },
    {
        route: "Payment",
        path: "payment",
        element: <PaymentOutlet />,
        icon: <IoMdWallet />,
        shortcut: true,
        children: [
          {
            route: "View Payments",
            path: "view payments",
            element: <Viewallpayment />,
            children: [],
            shortcut: true,
          },
        //   {
        //     route: "Add Payment",
        //     path: "Add Payment",
        //     element: <Addpayment />,
        //     children: [],
        //     shortcut: true,
        //   }
        ]
      },
  ];
  return routes;
}
