import { useEffect, useState } from "react";
import studentGlobalDataStore from "../../../../../store/_global_studentData";
import { CgNotes } from "react-icons/cg";
import { FiDownload } from "react-icons/fi";
import { VscFeedback } from "react-icons/vsc";
import { Form, Modal } from "react-bootstrap";
import { Button } from "primereact/button";
import Loader from "../../../../../common/loader";
import { Rating } from "primereact/rating";
import protectedStudentApiService from "../../../../../services/_protected_student_api";
import { toast } from "react-toastify";
export default function LiveClassesList() {
  const { liveClass, setLiveClass } = studentGlobalDataStore();
  const [show, setShow] = useState(false);
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { postStudentFeedback } = protectedStudentApiService();
  const [feedbackCreeds, setFeedbackCreeds] = useState<any>({
    student_classes_id: "",
    feedback: "",
    ratings: "",
    classengage: "",
    knowledge: "",
    rateclass: "",
    class_interect: "",
  });
  useEffect(() => {
    if (localStorage.getItem("live-class")) {
      setLiveClass(JSON.parse(localStorage.getItem("live-class") || ""));
    }
  }, []);
  const onValueChange = (val: any) => {
    setFeedbackCreeds({ ...feedbackCreeds, ...val });
  };
  const submitFeedbackForm = async () => {
    const res: any = await postStudentFeedback({
      ...feedbackCreeds,
      ...selectedClass,
    });
    if (res == 1) {
      toast.success("Feedback Successfully Given");
      setFeedbackCreeds({
        student_classes_id: "",
        feedback: "",
        ratings: "",
        classengage: "",
        knowledge: "",
        rateclass: "",
        class_interect: "",
      });
      handleClose();
    } else {
      toast.success("Something wents wrong");
      setFeedbackCreeds({
        student_classes_id: "",
        feedback: "",
        ratings: "",
        classengage: "",
        knowledge: "",
        rateclass: "",
        class_interect: "",
      });
      handleClose();
    }
  };
  return (
    <>
      <>
        {!liveClass && <Loader />}
        {liveClass && (
          <>
            <div className="card enrolled my-2">
              <h5 className="heading">Live Classes</h5>
              <div className="flex-start class-overflow">
                {/* {liveClass?.classList.map((e: any) => ( 
               
              ))} */}

                {liveClass?.classList.map((e: any) => (
                  <>
                    {/* {new Date(e.date) >= new Date() && ( */}
                    {/* {e.class_completed == 0 || new Date(e.date) >= new Date() && ( */}
                    {e.class_status == "upcoming" && (
                      <div className="card  m-2 class-card">
                        <div className="details p-4">
                          <div className="flex-between">
                            <p className="text-primary">
                              Class {e.class_number}
                            </p>
                            <p
                              className={
                                e.student_cls == 1
                                  ? "schedule bg-success "
                                  : e.student_cls == 0
                                  ? "schedule bg-danger "
                                  : "schedule bg-info"
                              }
                            >
                              {e.student_cls == 1
                                ? "Present"
                                : e.student_cls == 0
                                ? "Absent"
                                : "Upcoming"}
                            </p>
                          </div>
                          <h6 className="  mt-2">{e.topics?(
                            <>
                          {e.topics}  
                            </>
                          ):(<>
                          {e.course_name}
                          </>)}</h6>
                          <div className="sub">
                            <span className="text-gray">
                              Instructor : {e.name}
                            </span>
                            <br />
                            <span className="text-gray">
                              Date : {e.date} | Time : {e.start_time}
                            </span>
                            <div className="buttons row  ">
                              <div className="col-sm">
                                <a href={e.link}>
                                  <div className="btn mt-2 btn-primary flex-center btn-sm btn-wide">
                                    <img
                                      src="/assets/svg/LiveClass.svg"
                                      className="mx-1"
                                      alt=""
                                    />
                                    Join Class
                                  </div>
                                </a>
                              </div>
                              {/* <div className="col-sm-2 flex-center">
                          <button className="icon-btn btn-sm mt-2">
                            <img src="/assets/svg/Notes.svg" alt="" />
                          </button>
                        </div>  */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <hr />
              <h5 className="heading">Past Classes</h5>
              <div className="flex-start class-overflow">
                {liveClass?.classList &&
                  liveClass?.classList.map((e: any) => (
                    <>
                      {/* {e.class_completed == 1 || new Date(e.date) < new Date() && ( */}
                      {e.class_status == "pass" && (
                        <div className="card  m-2 class-card">
                          <div className="details p-4">
                            <div className="flex-between">
                              <p className="text-primary">
                                Class {e.class_number}
                              </p>
                              <p
                                className={
                                  e.student_cls == 1
                                    ? "schedule bg-success "
                                    : e.student_cls == 0
                                    ? "schedule bg-danger "
                                    : "schedule bg-info"
                                }
                              >
                                {e.student_cls == 1
                                  ? "Present"
                                  : e.student_cls == 0
                                  ? "Absent"
                                  : "Unknown"}
                                {/* {e.class_completed == 1 ? "Completed" : ""} */}
                              </p>
                            </div>
                            <h6 className="  mt-2">{e.course_name}</h6>
                            <div className="sub">
                              <span className="text-gray">
                                Instructor : {e.name}
                              </span>
                              <br />
                              <span className="text-gray">
                                Date : {e.date} | Time : {e.start_time}
                              </span>
                              <div className="buttons flex-between  ">
                                <div className="w-100 mr-2">
                                  <a href={e.recorded_link}>
                                    <div className="btn mt-2 btn-primary flex-center btn-sm btn-wide">
                                      <FiDownload size={23} className="mr-2" />{" "}
                                      Download
                                    </div>
                                  </a>
                                </div>
                                {/* <div className="col-sm-2 flex-center">
                            <button className="icon-btn btn-sm mt-2">
                              <img src="/assets/svg/Notes.svg" alt="" />
                            </button>
                          </div> */}
                                <div className=" flex-center mr-2">
                                  {/* <a href={e.note_docs}> */}
                                  <a href={`https://dataspaceacademylearning.com/api/public/docs/${e.note_docs}`} download>
                                    <button className="icon-btn btn-sm mt-2">
                                      <CgNotes size={23} />
                                    </button>
                                  </a>
                                </div>
                                <div className=" flex-center">
                                  <button
                                    onClick={() => {
                                      setSelectedClass(e);
                                      handleShow();
                                    }}
                                    className="icon-btn btn-sm mt-2"
                                  >
                                    <VscFeedback size={23} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
            {selectedClass && (
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Give Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {" "}
                  <div className="modal-body">
                    <div className="row">
                      <h4 className="col text-primary text-center">
                        {selectedClass.course_name}
                      </h4>
                    </div>
                    <h5 className="col text-gray text-center mb-3">
                      Teacher Name: {selectedClass.name}
                    </h5>
                    <div className="flex-start">
                      {" "}
                      <p className="schedule col text-center">
                        Date: {selectedClass.date}
                      </p>
                      <p className="schedule bg-success col text-center">
                        Time: {selectedClass.start_time} -{" "}
                        {selectedClass.end_time}
                      </p>
                    </div>

                    {(() => {
                      if (selectedClass.feedback_completed == 1) {
                        return (
                          <>
                            <h2 style={{ color: "green" }}>
                              Feedback already given.
                            </h2>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div>
                              {/* <label>Feedback</label><br></br>
                                                    <textarea className='form-control' onChange={(e) => { setstufeedback(e.target.value) }}></textarea> */}
                              <p style={{ marginBottom: "5px" }}>
                                How engaging was the class ?
                              </p>
                              <input
                                type="radio"
                                name="classengage"
                                value="Excellent"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Excellent &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="classengage"
                                value="Good"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Good &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="classengage"
                                value="Not upto the mark"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Not upto the mark &nbsp; &nbsp;
                              <br />
                              <br />
                              <p style={{ marginBottom: "5px" }}>
                                How Knowledgable was the class ?
                              </p>
                              <input
                                type="radio"
                                name="knowledge"
                                value="Excellent"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Excellent &nbsp; &nbsp;
                              <input
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                                type="radio"
                                name="knowledge"
                                value="Good"
                              />
                              &nbsp;Good &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="knowledge"
                                value="Not meaningful"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Not meaningful &nbsp; &nbsp;
                              <br />
                              <br />
                              <p style={{ marginBottom: "5px" }}>
                                Rate Your Class today{" "}
                              </p>
                              <input
                                type="radio"
                                name="rateclass"
                                value="Very Helpful"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Very Helpful &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="rateclass"
                                value="Class was engaging"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Class was engaging &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="rateclass"
                                value="Class was boring "
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Class was boring &nbsp; &nbsp;
                              <br />
                              <br />
                              <p style={{ marginBottom: "5px" }}>
                                How was your Instructor today{" "}
                              </p>
                              <input
                                type="radio"
                                name="class_interect"
                                value="Very helpful with all topics"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Very helpful with all topics &nbsp; &nbsp;
                              <input
                                type="radio"
                                name="class_interect"
                                value="Was only able to understand few"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Was only able to understand few &nbsp;
                              &nbsp;
                              <input
                                type="radio"
                                name="class_interect"
                                value="Could not understand"
                                onChange={(e) => {
                                  onValueChange({
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                              &nbsp;Could not understand &nbsp; &nbsp;
                              <br />
                              <br />
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <label>
                                  Any suggestion for improvement to DataSpace
                                  Academy class
                                </label>
                                <br />
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  name="feedback"
                                  className="form-control w-100"
                                  value={feedbackCreeds.feedback}
                                  onChange={(e) => {
                                    onValueChange({
                                      [e.target.name]: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </div>

                            <div className="row  ">
                              <p className="mx-2 mt-2">Rate your class</p>
                              <Rating
                                value={feedbackCreeds.ratings}
                                onChange={(e) => {
                                  onValueChange({
                                    ratings: e.value,
                                  });
                                }}
                                size={30}
                                stars={5}
                                cancel={false}
                              />
                            </div>
                          </>
                        );
                      }
                    })()}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleClose}>Close</Button>
                  <Button
                    className="p-button-success"
                    onClick={() => {
                      submitFeedbackForm();
                    }}
                  >
                    Give Feedback
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </>
        )}
      </>
    </>
  );
}
