import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link } from "react-router-dom";

export default function StudentsReview() {
  const tablesStructure: Columns[] = [
    {
      data_name: "student_name",
      header: "Student Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "faculty_name",
      header: "Faculty Name",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "date",
      header: "date",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "feedback",
      header: "Feedback",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "ratings",
      header: "Faculty Ratings",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Class",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <>0</>,
    },
    {
      data_name: "to_student_ratings",
      header: "Student Ratings",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <>0</>,
    },
    {
      data_name: "options",
      header: "Others",
      sortable: true,
      dataFilter: (data: any, key: any) =>{
      //  console.log(data);
        var rat = JSON.parse(data.other_data);
        if(rat==null){
          
        }else if(rat==undefined){

        }else{
            return(
                <>
                <div style={{border:"1px solid #000", padding:"6px"}}>
                <p>Class Engage: {rat.classengage}</p>
                <p>Knowledge: {rat.knowledge}</p>
                <p>Rate Class: {rat.rateclass}</p>
                <p>Class Interact: {rat.class_interect}</p>
                </div>
                </>
            )
        }
      },
    },

    // {
    //   data_name: "total_courses",
    //   header: "Total Courses",
    //   sortable: true,
    //   dataFilter: (data: any, key: any) => data[key] || <></>,
    // },
    // {
    //   data_name: "total_class_completed",
    //   header: "Total Completed Classes",
    //   sortable: true,
    //   dataFilter: (data: any, key: any) => data[key] || <></>,
    // },
  ];
  const { allStudents } = globalDataStore();
  const { get_student_remarks } = protectedApiService();
  const [allData, setAllData] = useState(null);
  useEffect(() => {
    //getData();
    getFromApi();
  }, []);

  // const getData = async () => {
  //   if (allStudents?.length) {
  //     setAllData(allStudents);
  //     console.log(allStudents);
  //   } else {
  //     getFromApi();
  //   }
  // };
  const getFromApi = async () => {
    const res: any = await get_student_remarks("all");
    //console.log(res);
    setAllData(res);
  };
  return (
    <>
      <PrimeDataTable
        data={allData || null}
        structure={tablesStructure}
        title={"All Students Review"}
        isForStudents
        onRefresh={getFromApi}
        message
        // filterDropdown={[
        //   { filter: "total_courses", header: "Total Courses No" },
        //   {
        //     filter: "total_class_completed",
        //     header: "Total Completed Classes",
        //   },
        // ]}
      />
    </>
  );
}
