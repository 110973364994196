import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Jobredirect(){

    const navigate = useNavigate();
    const { id } = useParams();
//console.log(id);

useEffect(()=>{
localStorage.setItem("dataspacemanagementlearning_job_ref",id);
setTimeout(()=>{
//console.log(window.location.origin);
window.location.href=window.location.origin;
},200)
},[])

    return(
        <>
        Redirecting ........
        </>
    )
}