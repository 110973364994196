import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsClockHistory } from "react-icons/bs";
import { IoBriefcaseOutline, IoLogoWhatsapp, IoLogoFacebook, IoCopyOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import { SlGraduation } from "react-icons/sl";
import { Chip } from "primereact/chip";
import protectedStudentApiService from "../../../../services/_protected_student_api";
import userState from "../../../../store/_userState";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import Loading from "../../../../common/loader";
import ShowMoreText from "react-show-more-text";
export default function SelectedJobs() {
  // const {getLocalUser} = userState();
  // const user = getLocalUser();
  const job = {"job_id":"","title":""};
  //const [jobb, setjobb] = useState<any>(job);
  //const job = [];
  const location = useLocation();
    //console.log(location.state);
  // const job = location.state.job;
  

  const { postApplyJob } = protectedStudentApiService();
  const { user } = userState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [applied, setApplied] = useState(false);
  // const [allJobs, setAllJobs] = useState<any>(location.state.allJob);
   const [allJobs, setAllJobs] = useState([]);
  const { getAllJobs } = protectedStudentApiService();
  const [recomended, setRecomended] = useState<any>(null);
  const [apply_loader, setapply_loader] = useState(false);

//console.log(JSON.parse(atob(atob(share_code))));
    //console.log(atob(atob(JSON.parse(share_code))));

  const [creeds, setCreeds] = useState<any>({
    jobb: {},
    file: null,
  });

   // const [creeds, setCreeds] = useState<any>(null);



useEffect(()=>{
  getData();


},[])


const getData = async () => {
  const resp: any = await getAllJobs();
  //console.log(resp);
  setAllJobs(resp.filter((x: any) => x.course_id == user.course_id));
  get_data_prev(resp);
};


const get_data_prev = (resp)=>{
  if(location.state==null){
   // console.log("job null");

      var job_data = localStorage.getItem("dataspacemanagementlearning_job_ref");
  if(job_data!=null){
   
//console.log("has data on local storage");
var jd = JSON.parse(atob(atob(job_data)))

//console.log(resp);
var ttk = [];
if(jd!=null){
  resp.forEach((ck)=>{
if(ck["job_id"]==jd.job_id){

ttk = ck;


}
  })
}
var dtcj = {
  jobb:ttk,
  file:null
}
setCreeds(dtcj);
console.log(allJobs);


  }else{

    console.log("Empty value local storage");

  }

  }else{
  const job = location.state.job;
//setjobb(location.state.job);
var dt = {
  jobb:location.state.job,
  file:null
}
setCreeds(dt);

    //console.log(location.state.job);
   // console.log(jobb);
  }
}


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onValueChange = (val: any) => {
    setCreeds({ ...creeds, ...val });
  };
  const onApply = async () => {
  //console.log(creeds.cv);
  var jb = creeds["jobb"];
    if (jb.job_id==""){
      toast.error("Error! Job ID not found. Please reload again");
    }else if(creeds.cv==undefined){
      toast.error("Error! Please add CV");
    }else{
      setapply_loader(true);
    const fd = new FormData();

     fd.append("job_id",jb.job_id);
     fd.append("user_id",user.user_id);
     fd.append("cv",creeds.cv);

     console.log(fd);
     console.log("apply");
     const res = await postApplyJob(fd);

      // const res = await postApplyJob({
      //   job_id: job.job_id,
      //   user_id: user.user_id,
      //   cv: creeds.cv,
      // });
      if (res == 1) {
        setapply_loader(false);
        showApplied();
        setTimeout(() => {
          handleClose();
          navigate(-1);
        }, 3500);
      }else if(res==2){
        setapply_loader(false);
          toast.error("You are already applied");

          // setTimeout(()=>{
          //     window.location.reload();
          // },2000)
      }else{
        
        setapply_loader(false);
      }
    }
  };
  const showApplied = () => {
    setApplied(true);
  };




const job_whatsapp_share = (title, job_id)=>{
  const n_ary = {
    job_id:job_id,
    ref_id:user.user_id
      }

    const share_code = btoa(btoa(JSON.stringify(n_ary)));
window.location.href="whatsapp://send?text=Announced a job for "+title+". Click to get details "+window.location.origin+"/job/"+share_code;
//console.log(share_code);
}

const job_fb_share = ()=>{

}

const job_copy_share = (job_id)=>{
  const n_ary = {
    job_id:job_id,
    ref_id:user.user_id
      }

    const share_code = btoa(btoa(JSON.stringify(n_ary)));
 // console.log("clicked");
  var dt = window.location.origin+"/job/"+share_code;
  navigator.clipboard.writeText(dt);
  toast.success("Copied");
}

  return (
    <>
      <>
        {!creeds.jobb && <Loading />}
        {creeds.jobb && (
          <>
            <div className="row">
              <div className="col-sm-7">
                <div className="card p-4 jobs-card">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5 className="heading">
                        {creeds.jobb.title || "Front-End Engineer React JS"}
                      </h5>
                    </div>
                    <div className="col-sm-6 flex-end">
                      <h5 className="heading text-primary">
                        {creeds.jobb.package || "Rs. 10 - 15 LPA"}
                      </h5>
                    </div>
                  </div>
                  <p className="text-gray">
                    {" "}
                    {creeds.jobb.company || "Numax Data Lab Pvt. Ltd."}
                  </p>
                  <div className="row">
                    <div className="chips btn-sm    ">
                      <IoBriefcaseOutline className="mr-2" />{" "}
                      {creeds.jobb.experience} years
                    </div>
                    <div className="chips btn-sm   ">
                      <BsClockHistory className="mr-2" />
                      Full Time
                    </div>
                    <div className="chips btn-sm  ">
                      <GrLocation className="mr-2" />{" "}
                      {creeds.jobb.location || "Hyderabad"}
                    </div>
                    <div className="chips btn-sm   ">
                      <SlGraduation className="mr-2" />
                      {creeds.jobb.education || ""}
                    </div>
                  </div>
                  <div style={{display:"flex", justifyContent:"space-between"}}>
                  <div className="flex-start mt-2">
                    <button onClick={handleShow} className="btn btn-primary">
                      Apply Now
                    </button>
                  </div>
                  <div>

                  {/* <IoLogoWhatsapp className="mr-2" style={{fontSize:"25px", color:"green", cursor:"pointer"}} onClick={()=>{job_whatsapp_share(creeds.jobb.title,creeds.jobb.job_id)}} />
                  <IoLogoFacebook className="mr-2" style={{fontSize:"25px", color:"blue",  cursor:"pointer"}} onClick={()=>{job_fb_share()}} />
                  <IoCopyOutline className="mr-2" style={{fontSize:"25px", color:"",  cursor:"pointer"}}  onClick={()=>{job_copy_share(creeds.jobb.job_id)}} />
                  */}
                  </div>
                  </div>
                 
                 
                  <hr />
                  <div className="mt-2 content">
                    <h5 className="heading">Job Description</h5>
                    <div
                      className="text-gray"
                      dangerouslySetInnerHTML={{
                        __html: creeds.jobb.job_description,
                      }}
                    ></div>
                  </div>
                  <div className="mt-2 content">
                    <h5 className="heading">Qualifications </h5>
                    
                    <div  className="text-gray" dangerouslySetInnerHTML={{
                        __html: creeds.jobb.qualification,
                      }}></div>
                  </div>
                  <div className="mt-2 content">
                    <h5 className="heading">Terms & Conditions</h5>
                    <p className="text-gray">
                      {creeds.jobb.terms_condition ||
                        "lorem ipsum dolor sit amet, consectetur adip"}
                    </p>
                  </div>
                  <div className="mt-2 content">
                    <h5 className="heading">Skill Required</h5>
                    <div className="flex align-items-center flex-wrap">
                      {creeds.jobb.skills &&
                        JSON.parse(creeds.jobb.skills).map((x: any) => (
                          <Chip label={x} className="mb-2 custom-chip" />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 job-recommended">
                <h6 className="text-gray mx-2 mt-2">Recommended</h6>
                {allJobs &&
                  allJobs.map((x: any) => (
                    <div
                      onClick={() => {
                        setCreeds({
                          ...creeds,
                          job: x,
                        });
                      }}
                      className="card p-4 jobs-card cursor-pointer"
                      style={{ maxWidth: "45rem" }}
                    >
                      <div className="flex-between">
                        <h5 className="heading">
                          {x.title || "Front-End Engineer React JS"}
                        </h5>
                        <h5 className="heading text-primary">
                          {x.package || "Rs. 10 - 15 LPA"}
                        </h5>
                      </div>
                      <p className="text-gray">
                        {" "}
                        {x.company || "Numax Data Lab Pvt. Ltd."}
                      </p>
                      <div className="row ">
                        <div className="chips     ">
                          <IoBriefcaseOutline className="mx-2" /> {x.experience}{" "}
                          years
                        </div>
                        <div className="chips    ">
                          <BsClockHistory className="mx-2" />
                          {x.duration}
                        </div>
                        <div className="chips   ">
                          <GrLocation className="mx-2" />{" "}
                          {x.location || "Hyderabad"}
                        </div>
                        <div className="chips    ">
                          <SlGraduation className="mx-2" />
                          {x.education || ""}
                        </div>
                      </div>
                      <ShowMoreText
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="text-gray mt-2"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                      >
                        {" "}
                        <div
                          className="text-gray"
                          dangerouslySetInnerHTML={{
                            __html: x.job_description,
                          }}
                        ></div>
                      </ShowMoreText>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        {applied ? (
          <>
            <div className="flex-center">Applied</div>
          </>
        ) : (
          <>

          {
            apply_loader ? (
                <>
                <div style={{textAlign:"center", padding:"20px"}}>
                <img src="/assets/loader.svg" style={{height:"100px;", width:"100px"}} />
                <p>Submitting......</p>
                </div>
                
                </>
            ):(
               <>
               
                <Modal.Header closeButton>
              <Modal.Title>Submit your CV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="applying mx-2">
                <div className="mb-2">
                  <h5 className="heading">Job: {creeds.job?.title}</h5>
                </div>
                <div className="mb-3">
                  <span className="text-gray text-sm">Upload CV</span>
                  <div className="flex-start">
                    <input
                      type="file"
                      className="form-control"
                      name="cv"
                      id="cv"
                      accept="application/msword, application/pdf"
                      placeholder="Submit your file  here"
                      onChange={(e: any) =>
                        onValueChange({
                          [e.target.name]: e.target.files[0],
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="p-btn-gray  " onClick={handleClose}>
                Close
              </Button>
              <Button className="p-btn-success" onClick={onApply}>
                Apply
              </Button>
            </Modal.Footer>
               
               </>
            )
          }

           
          </>
        )}
      </Modal>
    </>
  );
}
