import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader2 from "../../../../../common/loader2";
import { Link } from "react-router-dom";
export default function ScheduleList() {
  const tablesStructure: Columns[] = [
        {
      data_name: "cohort",
      header: "Cohort",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "Date & Time",
      sortable: false,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            {data.date} {data.time}
          </>
        );
      },
    },
    {
      data_name: "instructor_name",
      header: "Instructor",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "location",
      header: "Location",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "timing",
      header: "Timing",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_text",
      header: "Text",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "operation",
      header: "Operation",
      sortable: false,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            <Link to="/Home/Students/live/Update Schedule Data" state={data}>
              <Button
                icon="pi pi-file-edit"
                className="p-button-rounded p-button-primary p-button-outlined"
                aria-label="Delete"
              />
            </Link>
            <Button
              onClick={() => deleteOneSchedule(data)}
              icon="pi pi-trash
                "
              className="mx-2 p-button-rounded p-button-danger p-button-outlined"
              aria-label="Delete"
            />
          </>
        );
      },
    },
  ];

  const { get_schedule_list_data, delete_available_schedule } =
    protectedApiService();
  const [schedule_data, setschedule_data] = useState<any>({});

  useEffect(() => {
    get_schedule_list();
  }, []);

  const get_schedule_list = async () => {
    const data = await get_schedule_list_data();
    // console.log(data);
    setschedule_data(data);
  };

  const deleteOneSchedule = async (data: any) => {
    const conf = window.confirm("Are you sure to delete?");
    if (conf == true) {
      const res: any = await delete_available_schedule(
        data.available_schedule_id
      );
      if (res == 1) {
        toast.error("Successfully Deleted");
        get_schedule_list();
      } else {
        toast.error("Error !");
      }
    }
  };

  return (
    <>
      <div className="das-exs ">
        <div className="flex-end mx-4">
          <Link to="/Home/Students/live/Add New Schedule">
            <button className="btn btn-primary">Add Schedule</button>
          </Link>
        </div>
      </div>
      <PrimeDataTable
        data={schedule_data || []}
        structure={tablesStructure}
        title={"Schedule List"}
        isForStudent
        onRefresh={get_schedule_list}
        options
        // filterDropdown={[
        //     { filter: "course_name", header: "Course Name" },
        //   { filter: "date", header: "Date" },

        // ]}
      />
    </>
  );
}
