import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
export default function SupportEnquiry(){
    const { get_support_enquiry_data } = protectedApiService();
    const [support_enquiry, setsupport_enquiry] = useState();

useEffect(()=>{
    get_sup_data();
},[])

const get_sup_data = async()=>{
const data = await get_support_enquiry_data();
setsupport_enquiry(data);
}


const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
        data_name: "email",
        header: "Email",
        sortable: true,
        dataFilter: (data: any, key: any) => data[key] || <></>,
      },
    {
      data_name: "title",
      header: "Title",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "message",
      header: "Message",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "user_type",
      header: "User Type",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "date",
      header: "Date",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    }
  ];


    return(
        <>
              <PrimeDataTable
        data={support_enquiry || []}
        structure={tablesStructure}
        title={"Support Enqury"}
        isForStudent
        message
        onRefresh={get_sup_data}
      />
    </>
     
    )
}