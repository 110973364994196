import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useCallback, useEffect, useState } from "react";
import studentCommonApi from "../../../../services/_student_skillup_api";
import studentGlobalDataStore from "../../../../store/_global_studentData";
import { FilterDropdown } from "../../../../common/prime_data_table";
import protectedApiService from "../../../../services/_protected_api";
import Loader from "../../../../common/loader";
import userState from "../../../../store/_userState";
import { toast } from "react-toastify";
export default function Quizstart(){

    const { user } = userState();

    var datat = {
        "question":"",
        "a":"",
        "b":"",
        "c":"",
        "d":"",
        "question_no":"",
        "mcq_question_id":""
        
    }

var selected_data = {
    "mcq_question_id":"",
    "selected_option":"",
    "user_id":""
}

var qu_de = {
    total_questions: "0"
}


    const {get_quiz_questions, submit_quiz_data_from_student} = protectedApiService();
    const [quiz_details, setquiz_details] = useState<any>(qu_de);
    const [quiz_duration, setquiz_duration] = useState(0);
    const [quiz_questions, setquiz_questions] = useState([]);
    const [quiz_question_single, setquiz_question_single] = useState<any>(datat);
    const [quiz_question_selected_data, setquiz_question_selected_data] = useState<any>(selected_data);
    const [minutes, setminutes] = useState(0);
    const [seconds, setseconds] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const profile = location.state;


    useEffect(()=>{
      //  console.log(profile);
       get_questions();
    },[])

    const get_questions = async()=>{
const get_quiz = await get_quiz_questions(profile.mcq_quiz_id);
console.log(get_quiz);
setquiz_details(get_quiz.quiz_details)

if(get_quiz.questions!=null){
    var kc = 1;
    get_quiz.questions.forEach((v)=>{

        v["question_no"] = kc;

        kc++;
    })

    setquiz_question_single(get_quiz.questions[0]);

    setquiz_questions(get_quiz.questions);

    // get_quiz.questions.forEach((cc)=>{
    //     //console.log(cc);
    //     if(cc["question_no"]==1){
    //      //   console.log(cc);
    //      setquiz_question_single(cc);
    //     }
    // })

}

//console.log(quiz_question_single);

//

//console.log(get_quiz.quiz_details.quiz_duration);
var q_du = get_quiz.quiz_details.quiz_duration;

var milisec = q_du*60*1000;
var timer2 = q_du+":"+"01";
var i = 1;
var interval = setInterval(()=>{

    var timer = timer2.split(':');
    //by parsing integer, I avoid all extra string processing
    var minutes:any = parseInt(timer[0], 10);
    var seconds:any = parseInt(timer[1], 10);
    --seconds;
    minutes = (seconds < 0) ? --minutes : minutes;
    seconds = (seconds < 0) ? 59 : seconds;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    //minutes = (minutes < 10) ?  minutes : minutes;
   // console.log(minutes + ':' + seconds);
   setminutes(minutes);
   setseconds(seconds);
    if (minutes < 0) clearInterval(interval);
    //check if both minutes and seconds are 0
    if ((seconds <= 0) && (minutes <= 0)) clearInterval(interval);
    if((seconds <= 0) && (minutes <= 0)){
        // const u = {
        //     user_id:user.user_id,
        //     mcq_quiz_id:quiz_details.mcq_quiz_id
        
        // }
        
        // navigate(`/Home/assessment result`, { state: u });
    }
    timer2 = minutes + ':' + seconds;

},1000)

    }

  

const submit_data_next = async()=>{
    // console.log(quiz_questions);
    // console.log(quiz_question_single);

   // console.log(quiz_question_selected_data);

   console.log(quiz_question_selected_data.selected_option);

   if(quiz_question_selected_data.selected_option==""){
    toast.error("Please choose option");
   }else{

   const dt = await submit_quiz_data_from_student(quiz_question_selected_data);

var quiz_qu = quiz_question_single.question_no+1;
if(quiz_qu > quiz_details.total_questions){

const u = {
    user_id:user.user_id,
    mcq_quiz_id:quiz_details.mcq_quiz_id

}

navigate(`/Home/Global Exam Result`, { state: u });

}else{
    quiz_questions.forEach((ll)=>{
        if(ll["question_no"]==quiz_qu){
            setquiz_question_single(ll);
        }
    })
}

   }
}

const get_data_from_question = (clicked_option, data)=>{
var dt = {
    "mcq_quiz_question_id":data.mcq_quiz_question_id,
    "mcq_question_id":data.mcq_question_id,
    "selected_option":clicked_option,
    "user_id":user.user_id
}

setquiz_question_selected_data(dt);
console.log(quiz_question_selected_data)
}


    return(
        <>

<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato&amp;display=swap" />
                    <div className="col-md-6 text-end  align-self-end">
                <p className="timeandquestion heading">Question : {quiz_question_single.question_no}/{quiz_details.total_questions} <span className="px-3">|</span>Time Left : {minutes}min {seconds}secs
                </p>
            </div>
        <div className="container cntn">
        <div className="row px-3  ">
            <div className="col-md-12 border11 p-4 ">
                <div className="mcqs_section">
                    <div className="p-4 my-2">
                        <div className="row ">
                            <h3 className="fw-bold">
                            {quiz_question_single.question}
                                </h3>
                                <div className="col-md-6 my-2 cust_mg" onClick={()=>{get_data_from_question("a",quiz_question_single)}}>
                                    <div className={quiz_question_selected_data.mcq_quiz_question_id==quiz_question_single.mcq_quiz_question_id && quiz_question_selected_data.selected_option=='a' ? 'blue_color':'normal_color'}>
                                        <p className=" button_mcq"><span>A</span> {quiz_question_single.a}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 my-2 cust_mg"  onClick={()=>{get_data_from_question("b",quiz_question_single)}}>
                                    <div className={quiz_question_selected_data.mcq_quiz_question_id==quiz_question_single.mcq_quiz_question_id && quiz_question_selected_data.selected_option=='b' ? 'blue_color':'normal_color'}>
                                        <p className=" button_mcq"><span>B</span> {quiz_question_single.b}</p>
                                    </div>
                                </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-md-6 my-2 cust_mg"  onClick={()=>{get_data_from_question("c",quiz_question_single)}}>
                                <div className={quiz_question_selected_data.mcq_quiz_question_id==quiz_question_single.mcq_quiz_question_id && quiz_question_selected_data.selected_option=='c' ? 'blue_color':'normal_color'}>
                                    <p className=" button_mcq "><span>C</span> {quiz_question_single.c}</p>
                                </div>
                            </div>
                            <div className="col-md-6 my-2 cust_mg"  onClick={()=>{get_data_from_question("d",quiz_question_single)}}>
                                <div className={quiz_question_selected_data.mcq_quiz_question_id==quiz_question_single.mcq_quiz_question_id && quiz_question_selected_data.selected_option=='d' ? 'blue_color':'normal_color'}>
                                    <p className=" button_mcq "><span>D</span> {quiz_question_single.d}</p>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-end align-items-end buttons_end">
                            <div className="col text-end align-self-end">
                             {/* <button className="back">Back</button> */}
                             <button className="next" onClick={()=>{submit_data_next()}}>Next</button>
                            </div>
                           
                    </div>
                </div>

            </div>
        </div>
        </div>
        </div>
        </>
    )
}