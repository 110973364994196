import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";

export default function SubmittedResources() {
  const { getresourcesstudent } = protectedApiService();
  const [student_resources, setstudent_resources] = useState();

  useEffect(() => {
    get_resources_student();
  }, []);

  const get_resources_student = async () => {
    const data = await getresourcesstudent();
    // console.log(data);
    setstudent_resources(data);
  };

  const tablesStructure: Columns[] = [
    {
      data_name: "assigment_name",
      header: "Assignment Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "student_name",
      header: "Student Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course Name",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "result",
      header: "Result",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "assignment",
      header: "Submitted Result",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "final_result_status",
      header: "Final Result",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        if (data["final_result_status"] == true) {
          return <>True</>;
        } else {
          return <>False</>;
        }
      },
    },
  ];

  return (
    <>
      <PrimeDataTable
        data={student_resources || []}
        structure={tablesStructure}
        title={"Student Resource Submit"}
        isForStudent
        onRefresh={get_resources_student}
        filterDropdown={[{ filter: "course_name", header: "Course Name" }]}
      />
    </>
  );
}
