import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

export default function ViewAllApplied() {
  const tablesStructure: Columns[] = [
    {
      data_name: "title",
      header: "Jobs",
      sortable: true,
      dataFilter: (data: any, key: any) =>
        (
          <>
            <span className="limited-char">{data[key]}</span>
          </>
        ) || <></>,
    },
    {
      data_name: "company",
      header: "Company",
      sortable: true,
      dataFilter: (data: any, key: any) =>
        (
          <>
            <span className="limited-char">{data[key]}</span>
          </>
        ) || <></>,
    },
    {
      data_name: "email",
      header: "email",
      sortable: true,
      dataFilter: (data: any, key: any) => <>{data[key]}</> || <></>,
    },
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => <>{data[key]}</> || <></>,
    },
    // {
    //   data_name: "job_role",
    //   header: "Role",
    //   sortable: true,
    //   dataFilter: (data: any, key: any) =>
    //     (
    //       <>
    //         <span className="limited-char">{data[key]}</span>
    //       </>
    //     ) || <></>,
    // },
    {
      data_name: "contact_no",
      header: "Number",
      sortable: true,
      dataFilter: (data: any, key: any) => <>{data[key]}</> || <></>,
    },
    {
      data_name: "cv",
      header: "CV",
      sortable: true,
      dataFilter: (data: any, key: any) =>
        (
          <>
            <a href={data["cv_link"]} download>
              <Button className="btn btn-sm btn-primary">Download</Button>
            </a>
          </>
        ) || <></>,
    },
  ];
  const { getAllAppliedJobs, deleteJob } = protectedApiService();
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    getFromApi();
  };
  const getFromApi = async () => {
    const res: any = await getAllAppliedJobs();
    // console.log(res);
    setAllData(res);
  };
  return (
    <>
      <div className="das-exs ">
        <div className="flex-end mx-4">
          <Link to="/Home/Career/Add Jobs">
            <button className="btn btn-primary">Add Jobs</button>
          </Link>
        </div>
      </div>
      <PrimeDataTable
        data={allData || []}
        structure={tablesStructure}
        title={"All Jobs"}
        onRefresh={getFromApi}
        filterDropdown={[
          { filter: "title", header: "title" },
          { filter: "company", header: "company" },
        ]}
      />
    </>
  );
}
