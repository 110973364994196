import { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import globalDataStore from "../../../../../store/_globalData";
import commonApiService from "../../../../../services/_common_api";
import { toast } from "react-toastify";

export default function AddNewSchedule() {
  const { getAllCourses } = commonApiService();
  const { getAllFaculty } = protectedApiService();
  const { allCourses, setAllCourses, setAllFaculty, allFaculty } =
    globalDataStore();
    const [error_txt, seterror_txt] = useState("");
  const init = {
    cohort:"",
    course_name: "",
    eventdate: "",
    time: "",
    instructor: "",
    course_text: "",
    location: "",
    timing: "",
  };
  const { postAddNewSchedule } = protectedApiService();
  const [creeds, setCreeds] = useState(init);
  const onValueChange = (val: any) => {

    if(val["cohort"]!=null){
      var cohort = val["cohort"].toString();
      if(cohort.length>6){
        seterror_txt("Cohort name is not greater that 6 characters");
      }else{
        seterror_txt("");
      }
    }
    // console.log(val);
    setCreeds({ ...creeds, ...val });
 //   console.log(val);
    // console.log(register);
  };
  const onSubmit = async () => {
    console.log(creeds);
    const res: any = await postAddNewSchedule(creeds);
    if (res) {
      toast.success(res.msg);
      setCreeds(init);
    }
  };
  useEffect(() => {
    if (!allCourses) {
      fetchCourses();
    }
  }, []);
  const fetchCourses = async () => {
    const res: any = await getAllCourses();
    const res1: any = await getAllFaculty();
    setAllFaculty(res1);
    setAllCourses(res);
    console.log(allCourses);
  };
  return (
    <>
      <div className=" mt-3">
        <div className="card  p-3">
          <div className="row mx-2">
            <div className="heading mb-3">Create New Class Schedule</div>


            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Cohort Name
                </label>
                <input
                  type="text"
                  className="form-control mr-2"
                  name="cohort"
                  id="cohort"
                  value={creeds.cohort}
                  aria-describedby="dateHelp"
                  required
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <div style={{color:"red"}}>{error_txt}</div>
              </div>
            </div>


            <div className="col-sm-6 ">
              {allCourses && (
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Course
                  </label>
                  <select
                    id="course_name"
                    name="course_name"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option selected>Select Course</option>
                    {allCourses.map((x: any) => (
                      <option value={x.page_name}>{x.page_name}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="col-sm-6 ">
              {allFaculty && (
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Instructor
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="instructor"
                    name="instructor"
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option hidden selected>
                      Select Faculty
                    </option>
                    {allFaculty.map((x: any) => (
                      <option value={x.name}>{x.name}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Event Date
                </label>
                <input
                  type="date"
                  className="form-control mr-2"
                  name="eventdate"
                  id="eventdate"
                  value={creeds.eventdate}
                  aria-describedby="dateHelp"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Time
                </label>
                <input
                  type="time"
                  className="form-control mr-2"
                  name="time"
                  id="time"
                  value={creeds.time}
                  aria-describedby="dateHelp"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Select Location / Mode
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  id="location"
                  value={creeds.location}
                  aria-describedby="namelHelp"
                  placeholder="Bangalore / Online / Offline "
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Days
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="timing"
                  id="timing"
                  value={creeds.timing}
                  aria-describedby="namelHelp"
                  placeholder="Weekend / Week Days"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Course Text Details
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="course_text"
                  id="course_text"
                  value={creeds.course_text}
                  aria-describedby="namelHelp"
                  placeholder="Tuesday & Wednesday"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex-start p-3 mx-3">
            <button onClick={onSubmit} className="btn btn-primary">
              Add Schedule
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
