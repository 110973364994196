import { useLocation } from "react-router-dom";
import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Quizresult(){

    var tq = {
        "total_questions":""
    }

    var cor = {
        "correct":""
    }

    const {get_user_quiz_result} = protectedApiService();
    const location = useLocation();
    const [result, setresult] = useState<any>([]);
    const [details, setdetails] = useState<any>(tq);
    const [right_answer, setright_answer] = useState("");
    const profile = location.state;

    useEffect(()=>{
console.log(profile);
get_quiz_result();
    },[])

const get_quiz_result = async()=>{
    var co = await get_user_quiz_result(profile.user_id,profile.mcq_quiz_id);
    console.log(co);
    setresult(co.result);
    setdetails(co.quiz_details);
    setright_answer(co.correct);

    // if(co.result!=null){
    //     co.resultforEach(()=>{

    //     })
    // }
}


    return(
        <>
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato&amp;display=swap" />
<div className="container qu_res" style={{backgroundColor: "#EFFAFF"}}>
        <div className="row px-3  ">
            <div className="col-md-12 border11 p-4 ">

                <div className="card score_card ">
                    <div className="row p-3">
                        <div className="col-md-6 ">
                            <div className="row">
                                <div className="col-5 text-end">
                                    <h1>{right_answer}</h1>
                                </div>
                                <div className="col-4 text-start align-self-center">
                                    <h4>Questions</h4>
                                    <h5>Out of {details.total_questions}</h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6 text-end align-self-center">
                            <h5>Time Taken : 16min 22sec</h5>
                        </div> */}
                    </div>

                </div>
                <p className="fw-bold py-2 my-2"> Summary</p>
                <div className="mcqs_section">


                {result.map((res,index)=>{
                    
                    return(
                        <>
                         <div className="card p-4 my-2">

<div className="row ">
    <h3 className="fw-bold">
        {res.question}

        </h3>
        <div className="col-md-6 my-2">
            <div className={`${res.real_answer==res.submitted_answer && res.submitted_answer=="a" ? "right": ""} ${res.real_answer!=res.submitted_answer && res.submitted_answer=="a" ? "wrong":""} ${res.real_answer!=res.submitted_answer && res.real_answer=="a" ? "blue_color":""}`}>
                <p className=" button_mcq"><span>A</span> {res.a}</p>
            </div>

        </div>
        <div className="col-md-6 my-2">
            <div className={`${res.real_answer==res.submitted_answer && res.submitted_answer=="b" ? "right": ""} ${res.real_answer!=res.submitted_answer && res.submitted_answer=="b" ? "wrong":""} ${res.real_answer!=res.submitted_answer && res.real_answer=="b" ? "blue_color":""}`}>
                <p className=" button_mcq"><span>B</span> {res.b}</p>
            </div>

        </div>
</div>
<div className="row mt-1">
    <div className="col-md-6 my-2">
        <div className={`${res.real_answer==res.submitted_answer && res.submitted_answer=="c" ? "right": ""} ${res.real_answer!=res.submitted_answer && res.submitted_answer=="c" ? "wrong":""} ${res.real_answer!=res.submitted_answer && res.real_answer=="c" ? "blue_color":""}`}>
            <p className=" button_mcq "><span>C</span> {res.c}</p>
        </div>

    </div>
    <div className="col-md-6 my-2 ">
        {/* <div className=" right normal_color"> */}
        <div className={`${res.real_answer==res.submitted_answer && res.submitted_answer=="d" ? "right": ""} ${res.real_answer!=res.submitted_answer && res.submitted_answer=="d" ? "wrong":""} ${res.real_answer!=res.submitted_answer && res.real_answer=="d" ? "blue_color":""}`}>
            <p className=" button_mcq "><span>D</span> {res.d}</p>
        </div>

    </div>
</div>
</div>
                        </>
                    )
                })}
                    
                   

                    {/* <div class="card p-4 my-4">

                        <div class="row ">
                            <h3 class="fw-bold">
                                Q1. Which Of The Following Is NOT An Example Of Social Engineering?

                                </h1>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>A</span> Pishing Attacks</p>
                                    </div>

                                </div>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>B</span> Pishing Attacks</p>
                                    </div>

                                </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6  my-2 ">
                                <div class="border12 wrong">
                                    <p class=" button_mcq "><span>C</span> Pishing Attacks</p>
                                </div>

                            </div>
                            <div class="col-md-6 my-2">
                                <div class="normal_color ">
                                    <p class=" button_mcq "><span>D</span> Pishing Attacks</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card p-4 my-2">

                        <div class="row ">
                            <h3 class="fw-bold">
                                Q1. Which Of The Following Is NOT An Example Of Social Engineering?

                                </h1>
                                <div class="col-md-6 my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>A</span> Pishing Attacks</p>
                                    </div>

                                </div>
                                <div class="col-md-6 my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>B</span> Pishing Attacks</p>
                                    </div>

                                </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6 my-2">
                                <div class="border12 normal_color">
                                    <p class=" button_mcq "><span>C</span> Pishing Attacks</p>
                                </div>

                            </div>
                            <div class="col-md-6 my-2 ">
                                <div class=" right ">
                                    <p class=" button_mcq "><span>D</span> Pishing Attacks</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="card p-4 my-4">

                        <div class="row ">
                            <h3 class="fw-bold">
                                Q1. Which Of The Following Is NOT An Example Of Social Engineering?

                                </h1>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>A</span> Pishing Attacks</p>
                                    </div>

                                </div>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>B</span> Pishing Attacks</p>
                                    </div>

                                </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6  my-2 ">
                                <div class="border12 wrong">
                                    <p class=" button_mcq "><span>C</span> Pishing Attacks</p>
                                </div>

                            </div>
                            <div class="col-md-6 my-2">
                                <div class="normal_color ">
                                    <p class=" button_mcq "><span>D</span> Pishing Attacks</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card p-4 my-2">

                        <div class="row ">
                            <h3 class="fw-bold">
                                Q1. Which Of The Following Is NOT An Example Of Social Engineering?

                                </h1>
                                <div class="col-md-6 my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>A</span> Pishing Attacks</p>
                                    </div>

                                </div>
                                <div class="col-md-6 my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>B</span> Pishing Attacks</p>
                                    </div>

                                </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6 my-2">
                                <div class="border12 normal_color">
                                    <p class=" button_mcq "><span>C</span> Pishing Attacks</p>
                                </div>

                            </div>
                            <div class="col-md-6 my-2 ">
                                <div class=" right ">
                                    <p class=" button_mcq "><span>D</span> Pishing Attacks</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="card p-4 my-4">

                        <div class="row ">
                            <h3 class="fw-bold">
                                Q1. Which Of The Following Is NOT An Example Of Social Engineering?

                                </h1>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>A</span> Pishing Attacks</p>
                                    </div>

                                </div>
                                <div class="col-md-6  my-2">
                                    <div class="normal_color">
                                        <p class=" button_mcq"><span>B</span> Pishing Attacks</p>
                                    </div>

                                </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6  my-2 ">
                                <div class="border12 wrong">
                                    <p class=" button_mcq "><span>C</span> Pishing Attacks</p>
                                </div>

                            </div>
                            <div class="col-md-6 my-2">
                                <div class="normal_color ">
                                    <p class=" button_mcq "><span>D</span> Pishing Attacks</p>
                                </div>

                            </div>
                        </div>
                    </div> */}
                </div>





            </div>
        </div>

    </div>

        
        </>
    )
}