import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <>
      <div
        className="container-fluid flex-center flex-column"
        style={{
          height: "100vh",
        }}
      >
        <h3 className="text-center">404 page not found</h3>
        <p className="text-center">
          We are sorry but the page you are looking for does not exist.
        </p>
        <Link to="/">
          {" "}
          <button className="btn btn-primary">Home</button>
        </Link>
      </div>
    </>
  );
}
