import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import PrimeDataTable from "../../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import studentSkillUpApi from "../../../../../services/_student_skillup_api";
import commonApiService from "../../../../../services/_common_api";
import protectedApiService from "../../../../../services/_protected_api";

export default function AllStudentSkillUp() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "operation",
      header: "Operation",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            <Link to={`See Status/${data.user_id}`} state={data}>
              <Button className="facebook p-1" aria-label="Facebook">
                <i className="pi pi-chart-line "></i>
                <span className="px-1">See Status</span>
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  const { allStudents, setAllStudents } = globalDataStore();
  const { getAllStudents } = protectedApiService();
  const { getAllSkillUpStudents } = studentSkillUpApi();
  const [allData, setAllData] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    getSkillUpData();
  };
  const getSkillUpData = async () => {
    if (allStudents?.length) {
      setAllStudents(allStudents);
      const res: any = await getAllSkillUpStudents();
      const newData: any = res.map((x: any) => {
        return {
          ...allStudents.filter((s: any) => x.user_id == s.user_id)[0],
          ...x,
        };
      });
      setAllData(newData);
      console.log(allStudents);
    } else {
      getFromApi();
    }
  };
  const getFromApi = async () => {
    const res: any = await getAllStudents();
    const res2: any = await getAllSkillUpStudents();
    const newData: any = res2.map((x: any) => {
      return {
        ...res.filter((s: any) => x.user_id == s.user_id)[0],
        ...x,
      };
    });
    setAllData(newData);
    setAllStudents(res);
  };
  return (
    <>
      <PrimeDataTable
        data={allData || null}
        structure={tablesStructure}
        title={"All Students Report"}
        isForStudents
        onRefresh={getFromApi}
        noChecks
        //importable
      />
    </>
  );
}
