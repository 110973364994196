import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState, useCallback } from "react";
import { AiOutlineProject } from "react-icons/ai";
import { SiGoogleclassroom } from "react-icons/si";
import { MdOutlinePersonPin, MdOutlineDoneOutline } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { GiArchiveRegister } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import protectedStudentApiService from "../../../../services/_protected_student_api";
import studentGlobalDataStore from "../../../../store/_global_studentData";
import { ProgressBar } from "react-bootstrap";
import { FilterDropdown } from "../../../../common/prime_data_table";
import Loader from "../../../../common/loader";
import globalDataStore from "../../../../store/_globalData";
import commonApiService from "../../../../services/_common_api";
export default function LiveInteractiveClasses() {
  const navigate: any = useNavigate();
  const [filtered_courses, set_Filtered_course] = useState<any>(null);
  const [progress_steps, setProgressSteps] = useState<any>([]);
  const [changeableData, setChangeableData] = useState<any>([]);
  const { allCourses } = globalDataStore();
  const { getAllCourses } = commonApiService();
  useEffect(() => {
    const func = async () => {
      if (!allCourses) {
        const res: any = await getAllCourses();
      }
    };
    func();
    getCourses();
    getProgress();

    console.log(allCourses);
  }, []);
  const { getStudentClasses, getStudentProgress } =
    protectedStudentApiService();
  const { setAllCoursesGroup, setLiveClass } = studentGlobalDataStore();
  const getProgress = useCallback(async () => {
    const res: any = await getStudentProgress();
    setProgressSteps(res);
  }, [progress_steps]);

  const getCourses = useCallback(async () => {
    const res: any = await getStudentClasses();
    setRunningCourses(res);
  }, [filtered_courses]);
  function groupBy(arr: any, property: any) {
    return arr.reduce(function (memo: any, x: any) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  const setRunningCourses = (data: any) => {
    var course_set: any = [];
    data.forEach((element: any) => {
      course_set.push(element.course_name);
    });
    let uniqueItems: any = [...new Set(course_set)];
    let items = groupBy(data, "course_name");
    // console.log(items);
    setAllCoursesGroup(items);
    let grouped_course = uniqueItems.map((x: any) => {
      return {
        classList: items[x],
        course_name: x,
        course_id: items[x][0].course_id,
        total_classes: items[x].length,
        faculty: items[x][0].name,
        duration: items[x][0].duration,
        class_completed: items[x].reduce(
          (accumulator: any, current: any) =>
            accumulator + current.class_completed,
          0
        ),
        course: items[x][0].course,
        avg_duration:
          items[x].reduce(
            (accumulator: any, current: any) =>
              accumulator + parseInt(current.duration),
            0
          ) / items[x].length,
      };
    });

    //console.log(grouped_course);
    set_Filtered_course(grouped_course);
    setChangeableData(grouped_course);
  };
  const redirectToLiveClass = (x: any) => {
    x["course_id"] = x.course;
    // console.log(x);
    localStorage.setItem("storage_course_id", x.course);
    let nx: any = [];
    if (x != null) {
      var ik = 1;
      nx = x.classList.map((ck: any) => {
        var class_status = "";
        ck["class_number"] = ik;
        if (ck["class_completed"] == 1) {
          class_status = "pass";
        } else if (ck["class_completed"] == 0) {
          class_status = "upcoming";
        }
        ck["class_status"] = class_status;
        ik++;
        return ck;
      });
    }
    console.log({ ...x, classList: nx });
    setLiveClass({ ...x, classList: nx });
    localStorage.setItem("live-class", JSON.stringify({ ...x, classList: nx }));
    navigate("/StudentClasses/Courses/LiveClass", {
      state: { ...x, classList: nx },
    });
  };

  return (
    <>
      <>
        {!filtered_courses && <Loader />}
        {filtered_courses && (
          <div className="container enrolled">
            {filtered_courses?.length ? (
              <>
                <div className="row">
                  <div className="col-sm-3 flex-between">
                    <h5 className="heading">Active Courses</h5>
                  </div>
                  <div className="col-sm-9">
                    <div id="setps" className="steps">
                      {progress_steps &&
                        progress_steps.map((ps: any, index: any) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="track">
                                    <div
                                      className="step active"
                                      data-aos="zoom-in"
                                      data-aos-offset="300"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <GiArchiveRegister className="" />
                                      </span>{" "}
                                      <span className="text">Enrolled</span>{" "}
                                    </div>
                                    <div
                                      className={`step ${
                                        ps.assign_class == 1 ? "active" : ""
                                      }`}
                                      data-aos="zoom-in"
                                      data-aos-offset="300"
                                      data-aos-anchor="#right-screen"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <SiGoogleclassroom />
                                      </span>{" "}
                                      <span className="text">
                                        {" "}
                                        Assigned Class
                                      </span>{" "}
                                    </div>
                                    <div
                                      className={`step ${
                                        ps.course_completed == 1 ? "active" : ""
                                      }`}
                                      data-aos="zoom-in"
                                      data-aos-offset="300"
                                      data-aos-anchor="#right-screen"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <GoChecklist />
                                      </span>{" "}
                                      <span className="text">
                                        Course Completed
                                      </span>{" "}
                                    </div>
                                    <div
                                      className={`step ${
                                        ps.project_assigned == 1 ? "active" : ""
                                      }`}
                                      data-aos="zoom-in"
                                      data-aos-anchor="#right-screen"
                                      data-aos-offset="300"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <AiOutlineProject />
                                      </span>{" "}
                                      <span className="text">
                                        Project Assigned
                                      </span>{" "}
                                    </div>
                                    <div
                                      className={`step ${
                                        ps.interview == 1 ? "active" : ""
                                      }`}
                                      data-aos="zoom-in"
                                      data-aos-anchor="#right-screen"
                                      data-aos-offset="300"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <MdOutlinePersonPin />
                                      </span>{" "}
                                      <span className="text">
                                        Interview
                                      </span>{" "}
                                    </div>
                                    <div
                                      className={`step no-before${
                                        ps.placement == 1 ? "active" : ""
                                      }`}
                                      data-aos="zoom-in"
                                      data-aos-anchor="#right-screen"
                                      data-aos-offset="300"
                                      data-aos-easing="ease-in-sine"
                                    >
                                      {" "}
                                      <span className="icon">
                                        {" "}
                                        <MdOutlineDoneOutline />
                                      </span>{" "}
                                      <span className="text">
                                        Placement
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end my-2">
                    {filtered_courses && (
                      <FilterDropdown
                        allData={filtered_courses}
                        filterField={"course_name"}
                        setChangeableData={setChangeableData}
                        header={""}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  
                  {changeableData &&
                    changeableData.map((x: any) => (
                     
                      <>
                        <div className="col-sm-6 flex-center">
                          <div className="card shadow-sm m-2 course-card">

                        
                            <img
                              src={
                                (allCourses &&
                                  allCourses.filter(
                                    (z: any) => z.course_id == x.course_id
                                  )[0]?.featured_image) ||
                                "/assets/bg/register_bg.png"
                              }
                              alt=""
                            />

                        
                            <div className="details p-4">
                              <h5 className="heading">{x.course_name}</h5>
                              <div className="sub">
                                <span className="text-gray">
                                  Faculty : {x.faculty}
                                </span>
                                <br />
                                <span className="text-gray">
                                  Class Duration : {x.duration} Months
                                </span>
                                <button
                                  onClick={() => redirectToLiveClass(x)}
                                  className="btn mt-2 btn-primary btn-wide"
                                >
                                  View Details
                                </button>
                                <div className="my-3">
                                  <ProgressBar
                                    variant="success"
                                    now={x.class_completed}
                                    label={`${x.class_completed}%`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </>
            ) : (
              <>
                <div className="flex-center flex-column block-ui">
                  <CiLock size={35} />
                  <p className="heading">You don't have any active course</p>
                  <Link to="/Enrolled/All Courses/Courses">
                    <button className="btn btn-primary">Enroll Now</button>
                  </Link>
                </div>
              </>
            )}
          </div>
        )}
      </>
    </>
  );
}
