import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import AdminDashboard from "../pages/home/admin/admin_dashboard";
import { RiDashboardFill, RiNewspaperLine } from "react-icons/ri";
import { GoPerson } from "react-icons/go";
import ViewProfileDetails from "../pages/home/admin/common/view_profile";
import EditProfileDetails from "../pages/home/admin/common/edit_profile";
import { HiBriefcase, HiClipboardList } from "react-icons/hi";
import CareerOutlet from "../pages/home/admin/career/outlet_career";
import ViewAllJobs from "../pages/home/admin/career/view_all_jobes";
import EditJobs from "../pages/home/admin/career/edit_jobs";
import AddJobs from "../pages/home/admin/career/add_jobs";

import ViewStudentProfile from "../pages/home/student/student_profile";
import ViewAllApplied from "../pages/home/admin/career/view_apllied_jobs";
import ScheduledStudents from "../pages/home/admin/students/live_interactive/scheduled_students";
import ViewClass from "../pages/home/admin/students/live_interactive/view_class";
import { GiGraduateCap } from "react-icons/gi";
import StudentIndex from "../pages/home/admin/students/student_index";
import Jobaggrdashboard from "../pages/home/job_aggr_dashboard";

export default function Jobaggr() {
  const routes: any[] = [
    {
      route: "Base",
      path: "",
      element: <Navigate to="Dashboard" />,
      children: [],
      shortcut: true,
    },
    {
      route: "Dashboard",
      path: "Dashboard",
      element: <Jobaggrdashboard />,
    //element: <AdminDashboard />,
      icon: <RiDashboardFill />,
      children: [],
      shortcut: true,
    },
    {
      route: "View Profile",
      path: "View Profile",
      element: <ViewProfileDetails />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "Edit Profile",
      path: "Edit Profile",
      element: <EditProfileDetails />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "Profile",
      path: "Profile",
      element: <Outlet />,
      icon: <GoPerson />,
      children: [
        {
          route: "",
          path: "",
          element: <ViewStudentProfile />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Edit",
          path: "Edit",
          element: <ViewStudentProfile editable={true} />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
      ],
      shortcut: false,
    },

    {
      route: "Career",
      path: "Career",
      element: <CareerOutlet />,
      icon: <HiBriefcase />,
      children: [
        {
          route: "View All Jobs",
          path: "View All Jobs",
          element: <ViewAllJobs />,

          children: [],
          shortcut: true,
        },
        {
          route: "Edit Jobs",
          path: "Edit Jobs",
          element: <EditJobs />,

          children: [],
          shortcut: false,
        },
        {
          route: "Add Jobs",
          path: "Add Jobs",
          element: <AddJobs />,
          children: [],
          shortcut: false,
        },
        {
          route: "Applied For Job",
          path: "appliedJob",
          element: <ViewAllApplied />,
          children: [],
          shortcut: true,
        },
      ],
      shortcut: true,
    },

    {
      route: "Scheduled Students",
      path: "Scheduled Students",
      element: <ScheduledStudents />,
      shortcut: true,
      children: [],
    },
    {
      route: "Students",
      path: "Students",
      element: <StudentIndex />,
      icon: <GiGraduateCap />,
      shortcut: false,

      children: [
       

        {
          route: "View Class",
          path: "View Class",
          element: <ViewClass />,
          children: [],
          shortcut: false,
        },
          ],
        },
  ];
  return routes;
}
