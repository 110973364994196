import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader2 from "../../../../../common/loader2";
import { Columns } from "../../../../../interfaces/_common";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import Loader from "../../../../../common/loader";
import Loader3 from "../../../../../common/loader3";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import userState from "../../../../../store/_userState";

export default function ViewClass() {
  const location = useLocation();
  const profile = location.state[0];
  const navigate = useNavigate();

  const { accessToken, user } = userState();
  //console.log(profile);

  const { student_view_class, delete_student_class } = protectedApiService();
  const [student_class_data, setstudent_class_data] = useState<any>(null);

  const tablesStructure: Columns[] = [
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data["topics"] || data[key],
    },
    {
      data_name: "name",
      header: "Faculty",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Date & Time",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            {data["date"]} {data["start_time"]}:{data["end_time"]}
          </>
        );
      },
    },
    {
      data_name: "course_name",
      header: "Status",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        if (data["student_cls"] == 1) {
          return (
            <>
              <h6 style={{ color: "green" }}>Present</h6>
            </>
          );
        } else if (data["student_cls"] == 0) {
          <>
            <h6 style={{ color: "red" }}>Absent</h6>
          </>;
        }
      },
    },

    // {
    //   data_name: "Action",
    //   header: "Action",
    //   sortable: true,
    //   dataFilter: (data: any, key: any) => data[key] || <></>,
    // },

    {
      data_name: "options",
      header: "Action",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
          {(()=>{
            if(user.role<4){
              return(
              <>
              <Button
              className="p-btn-danger btn btn-danger"
              aria-label="Facebook"
              onClick={() => {
                delete_view_class(data);
              }}
            >
              <i className="pi pi-trash p-1"></i>
              <span className="p-1">Delete</span>
            </Button>
              </>
              )
            }
          })()}
            
          </>
        );
      },
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    get_student_class();
  }, []);

  const get_student_class = async () => {
    setLoading(true);
    const data = await student_view_class(profile.user_id);
    // console.log(data);
    setstudent_class_data(data);
    //setLoading(false);
  };

  const delete_view_class = async (data) => {
    const dt = window.confirm("Are you sure?");
    if (dt == true) {
      const ret: any = await delete_student_class(data.student_classes_id);
     // console.log(ret);
      if (ret == 1) {
        toast.error("Successfully Deleted");
        get_student_class();
      }
    }
  };

  return (
    <>
        <>
          {student_class_data && student_class_data.length ? (
            <PrimeDataTable
              data={student_class_data || []}
              structure={tablesStructure}
              title={"Student View Class"}
              onRefresh={get_student_class}
              filterDropdown={[
                { filter: "course_name", header: "Course Name" },
              ]}
            />

          
          ) : (
            <>
              <Loader3 />
            </>
          )}
            <button onClick={() => navigate(-1)} className="btn btn-danger" style={{color:"white",marginLeft:"10px"}}>
            {" "}
            Back
          </button>
        </>

    </>
  );
}
