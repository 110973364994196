import React, { useRef, useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import studentSkillUpApi from "../../../../../services/_student_skillup_api";
import { toast } from "react-toastify";
import Loader from "../../../../../common/loader";
import Lottie from "react-lottie";
import * as animationData from "../../../../../assets/lottie/youtube.json";
export default function SelflearningModuleVideoPlayer({
  playable,
  playNext,
  onRefresh,
}: any) {
  const { updateModulePlayer, getSingleVideo } = studentSkillUpApi();
  const [player, setPlayer] = useState<any>(null);
  const [state, setState] = useState<any>({
    total_duration: 0,
    completed_duration: 0,
    volume: 0,
    seekTo: 0,
    module_id: playable.module_id,
  });
  useEffect(() => {
    setPlayer(playable);
    console.log(playable);
    setState({
      ...state,
      total_duration: 0,
      completed_duration: playable.video_completed,
      volume: 0,
      seekTo: 0,
      module_id: playable.module_id,
    });
  }, [playable]);

  const ref: any = React.createRef();

  const onSeek = (e: any) => {
    console.log(e);
    setState({
      ...state,
      total_duration: ref.current.getDuration(),
      completed_duration: e,
      seekTo: e,
    });

    updatePlayDuration();
  };
  const updatePlayDuration = async () => {
    console.log();
    const res: any = await updateModulePlayer(state);
    if (res == "1") {
      // toast.success("Updated");
      onRefresh();
      playable.video_completed = ref.current.getCurrentTime();
    }
  };

  const onReady = () => {
    //
    if (ref) {
      setState({
        ...state,
        total_duration: ref.current.getDuration(),
        completed_duration: ref.current.getCurrentTime(),
        seekTo: playable.video_completed,
      });
      updatePlayDuration();
    }
  };
  const onNext = () => {
    updatePlayDuration();
    playNext();
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <>
      {!playable && <Loader />}
      {playable && playable && (
        <div className="player">
          <ReactPlayer
            ref={ref}
            style={{
              borderRadius: "10px",
              overflow: "hidden",
              maxWidth: "80rem",
            }}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            url={`https://dataspaceacademylearning.com/skillup/public/video/${playable?.video}`}
            width="100%"
            height="100%"
            controls={true}
            light={
              <img
                src={playable?.img || "../../../../assets/bg/register_bg.png"}
                height={"100%"}
                width={"100%"}
              />
            }
            onPlay={onReady}
            onSeek={onSeek}
            onDuration={(e: any) => {
              console.log(e);
            }}
            onEnded={onNext}
          />
          <div className="flex-start align-items-center mx-1 mt-2">
            <h5 className="heading  ">{playable.title}</h5>
            <p className="mx-3 mt-2">
              ({Math.floor(playable.video_duration / 60)}:
              {Math.floor(playable.video_duration % 60)} mins )
            </p>
          </div>
        </div>
      )}
    </>
  );
}
