import axios from "axios";
import globalDataStore from "../store/_globalData";
export default function commonApiService() {
  const _https = axios.create({
    baseURL: "https://academy.dataspaceacademy.com",
  });
  const { setAllCourses } = globalDataStore();
  const getAllCourses = async () => {
    try {
      const res = await _https.get("/api/get_all_courses/");
      // console.log(res.data);
      setAllCourses(res.data);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };
  const getPaymentDetails = async (data: any) => {
    try {
      const res = await _https.get("/enrolled/course/payment/success/" + data);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  return { getAllCourses, getPaymentDetails };
}
