import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import commonApiService from "../../../../../services/_common_api";
import globalDataStore from "../../../../../store/_globalData";
import studentGlobalDataStore from "../../../../../store/_global_studentData";
import Loader from "../../../../../common/loader";
import { Modal } from "react-bootstrap";
import { Button } from "primereact/button";

export default function RecommendedCourse() {
  const { getAllCourses } = commonApiService();
  const { allCourses, setAllCourses } = globalDataStore();
  const { skillUpModule, setSkillUpModule } = studentGlobalDataStore();
  const [rec, setRec] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [pricing, setPricing] = useState<any>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("skill-up")) {
      setSkillUpModule(JSON.parse(localStorage.getItem("skill-up") || ""));
    }
    if (!allCourses) {
      fetchCourses();
    }
  }, []);
  const fetchCourses = async () => {
    const res: any = await getAllCourses();
    setAllCourses(res);
  };
  useEffect(() => {
    if (skillUpModule?.course_id) {
      if (allCourses && skillUpModule) {
        setRec(
          allCourses.filter(
            (x: any) => x.course_id == skillUpModule?.course_id
          )[0]
        );
      }
    }
  }, [skillUpModule, allCourses]);

  return (
    <>
      <>
        {!rec && <Loader />}
        {rec && (
          <div className="container enrolled p-4">
            <h5 className="heading">Upgrade to paid course</h5>
            <div className="row">
              <div className="col-sm-6 flex-center ">
                <div className="card course-card shadow m-2  ">
                  <img
                    src={rec.featured_image || "/assets/bg/register_bg.png"}
                    alt=""
                  />
                  <div className="details p-4">
                    <h5 className="heading">
                      {rec.page_name || "Test Course"}
                    </h5>
                    <div className="sub">
                      <div className="flex-between">
                        <div className="left">
                          {/* <span className="text-gray">
                            Faculty : {rec.faculty || "Test Faculty"}
                          </span> */}

                          <span className="text-gray">
                            Class Duration : {rec.duration || "3"} Months
                          </span>
                        </div>
                        <div className="right">
                          <a href={`https://dataspaceacademy.com/${rec.slug}`}>
                            <button className="btn  btn-sm btn-outline-primary  ">
                              View Details
                            </button>
                          </a>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="col-sm-6 mt-2">
                          <button
                            onClick={() => {
                              handleShow();
                              setPricing(JSON.parse(rec.plans));
                            }}
                            className="btn btn-sm btn-primary btn-wide"
                          >
                            Enroll Now
                          </button>
                        </div>
                        {/* <div className="col-sm-6 mt-2">
                        <Link to="Already Enrolled" state={rec.classList}>
                          <button className="btn btn-sm btn-primary btn-wide">
                            Already Enrolled
                          </button>
                        </Link>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show && pricing}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row align-item-center">
            {pricing &&
              pricing.map((x: any) => (
                <div className="col-sm-4 flex-start-center">
                  <div className="plans-card shadow card mx-2 p-3">
                    <div className="flex-between">
                      <h4 className="text-warning">{x.plan_name}</h4>
                      <h5 className="text-success">{x.price} /-</h5>
                    </div>
                    <hr />
                    <p className="text-gray">Plan Description</p>
                    <div
                      dangerouslySetInnerHTML={{ __html: x.other_data }}
                    ></div>
                    <hr />
                    {x.price == "0" ? (
                      <Button
                        label="Free Enroll Now"
                        className=" p-button-success"
                      />
                    ) : (
                      <Button label="Punches Now" className="p-button" />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
