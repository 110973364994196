import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import Loader2 from "../../../../../common/loader2";
import { toast } from "react-toastify";
import LocalstorageStudentData from "../../../../../store/_localstorage_student_data";
import StudentAddCheck from "../../../../../store/_student_add_check";
export default function InterviewNotCompleted() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "operation",
      header: "Operation",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            <button
              onClick={() => {
                interview_schedule(data);
              }}
              className="btn btn-outline-primary btn-sm"
            >
              Change Status
            </button>
          </>
        );
      },
    },
  ];
  const { allStudents } = globalDataStore();
  // const allstudentsstored = LocalstorageStudentData();
  // StudentAddCheck();
  const { getAllStudents, interview_completed,retrieve_updated_data, procedure_interview_not_completed } = protectedApiService();
  useEffect(() => {
    getData();
  }, []);

  const [allData, setAllData] = useState<any>(null);

  const getData = async () => {
    // if (allStudents) {
    //   let data: any[] = allStudents.filter((x: any) => {
    //     if (!x.interview && x.project_assigned && x.course_completed) {
    //       return x;
    //     }
    //   });
    //   setAllData(data.length ? [...data] : []);
    // } else {
    //   getFromApi();
    // }
    getFromApi();
  };
  const getFromApi = async () => {
     const res: any = await procedure_interview_not_completed();

    setAllData(res);
  };

  const interview_schedule = async (data: any) => {
    const conf = window.confirm("Are you sure?");
    if (conf == true) {
      const dt: any = await interview_completed(data.user_id);
      if (dt == 1) {
        await retrieve_updated_data(data.user_id);
        toast.success("Successfully changed");
      }
    }
  };

  return (
    <>
      {allData ? (
        <PrimeDataTable
          data={allData}
          structure={tablesStructure}
          title={"Interview Not Completed"}
          isForStudent
          onRefresh={getFromApi}
          note
          message
          timeline
          options
          view
          filterDropdown={[{ filter: "course_name", header: "Course Name" }]}
        />
      ) : (
        <>
          <Loader2 />
        </>
      )}
    </>
  );
}
