import { useEffect } from "react";
import protectedApiService from "../services/_protected_api";
import LocalstorageStudentData from "./_localstorage_student_data";

export default function StudentAddCheck(){
    const {get_student_add_checker} = protectedApiService();
    const allstudentsstored = LocalstorageStudentData();


useEffect(()=>{
    get_student_check();
},[])

const get_student_check = async()=>{
    var nck = allstudentsstored;
    var lastItem = nck.slice(-1).pop();
   var data;
if(nck==null){
   data = await get_student_add_checker(0);
}else{
    data = await get_student_add_checker(lastItem.student_id);
//    console.log(data);
//    console.log(allstudentsstored);
}
   data.forEach((l)=>{
    allstudentsstored.push(l);
   })


   localStorage.setItem("get_student_details", btoa(JSON.stringify(allstudentsstored)));

}




}