import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import { RiDashboardFill, RiNewspaperLine } from "react-icons/ri";
import { GoPerson } from "react-icons/go";
import Jobaggrdashboard from "../pages/home/job_aggr_dashboard";
import { FaChalkboardTeacher } from "react-icons/fa";
import AllFaculty from "../pages/home/admin/faculty/all_faculty";
import SetFacultyTiming from "../pages/home/admin/faculty/set_faculty_timing";
import Schedule from "../pages/home/faculty/Schedule";
export default function Adminfacultyrouter(){
    const routes: any[] = [
        {
          route: "Base",
          path: "",
          element: <Navigate to="Dashboard" />,
          children: [],
          shortcut: true,
        },
        {
          route: "Dashboard",
          path: "Dashboard",
          element: <Jobaggrdashboard />,
        //element: <AdminDashboard />,
          icon: <RiDashboardFill />,
          children: [],
          shortcut: true,
        },
        {
          route: "Faculties",
          path: "faculties",
          element: <Outlet />,
          icon: <FaChalkboardTeacher />,
          no_collapse: true,
          children: [
            {
              route: "",
              path: "",
              element: <AllFaculty />,
              icon: <GoPerson />,
              children: [],
            },
            // {
            //   route: "Set Faculty Timing",
            //   path: "Set Faculty Timing",
            //   element: <SetFacultyTiming />,
            //   icon: <GoPerson />,
            //   children: [],
            //   shortcut: false,
            // },
            
            // {
            //   route: "Add Faculty",
            //   path: "Add Faculty",
            //   element: <AddFaculty />,
            //   icon: <GoPerson />,
            //   children: [],
            //   shortcut: false,
            // },
          ],
          shortcut: true,
        },
        {
          route: "Schedule",
          path: "Schedule",
          element: <Schedule />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
      ];
      return routes;
}