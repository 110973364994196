import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/_auth";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { Password } from "primereact/password";
import protectedApiService from "../services/_protected_api";
export default function Login() {
  const navigate = useNavigate();
  const [creeds, setCreeds] = useState<any>({
    username: "",
    password: "",
  });
  const { loginUser } = AuthService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { getAllStudents } = protectedApiService();

useEffect(()=>{

  //getFromApi();
},[])




  const onValueChange = (val: any) => {
    // console.log(val);
    setCreeds({ ...creeds, ...val });
    // console.log(val);
    // console.log(register);
  };
  const onLogin = async (e) => {
    e.preventDefault();
    // console.log(creeds);
    setLoading(true);
    let response: any = await loginUser(creeds);
    if (!response.status) {
      toast.error("Invalid credentials!");
      setCreeds({
        username: "",
        password: "",
      });
      setError(true);
    } else {
      toast.success("Login Successful!");
    }
    setLoading(false);
  };
  return (
    <>
      <div className=" container-fluid register-background ">
        <div className="row">
          <div className="col-md-7 register-text p-3">
            <div className="text">
              <h1 className="title-primary">LOGIN NOW!</h1>
              <h4 className="subtitle-primary">
                Get 100+ courses in developing a future-proof career in solid
                and impactful manpower in the security and Information Systems
                as per international trend.
              </h4>
            </div>
          </div>
          <div
            className="col-md-5 bg-white flex-center p-3"
            style={{ minHeight: "100vh" }}
          >
            <div className="mx-auto my-auto form register-form">
              <div className=" p-2 mt-5 d-flex">
                <div className="logo-reg  mx-auto">
                  <img src="/assets/svg/Logo.svg" alt="LOGO" />
                </div>
              </div>
              {/* <div className="mx-auto">
                            <p id="error" className="error">*This is an error message*</p>
                        </div> */}
              {/* <div className="slides">
                            <div className="slide1"> */}
              <form onSubmit={(e)=>{onLogin(e)}}>
                <div
                  className="p-3"
                  style={{
                    minHeight: "30rem !important",
                    maxHeight: "30rem!important",
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username/ Email
                    </label>
                    <input
                      type="text"
                      onChange={(e) =>
                        onValueChange({
                          [e.target.name]: e.target.value,
                        })
                      }
                      className={`form-control ${error && "invalid"}`}
                      name="username"
                      value={creeds.username}
                      id="username"
                      aria-describedby="namelHelp"
                      placeholder="Username"
                      onFocus={() => setError(false)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password{" "}
                    </label>
                    <Password
                      name="password"
                      id="password"
                      placeholder="Password"
                      onChange={(e) =>
                        onValueChange({
                          [e.target.name]: e.target.value,
                        })
                      }
                      value={creeds.password}
                      className="w-100"
                      toggleMask
                      feedback={false}
                    />

                    <div className="flex-end my-2  ">
                      <Link
                        className="text-sm"
                        style={{ color: "red" }}
                        to="/reset-password"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <div className="mb-3 ">
                    <button
                      type="submit"
                      className="btn btn-primary btn-wide "
                      disabled={loading}
                    
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {loading ? "Loggin..." : "Login"}
                    </button>
                  </div>

                  <div className="mx-auto mt-4">
                    <h5 className="subtitle-primary text-center  ">
                      Don't have an account ?{" "}
                      <a href="/register">Register now!</a>
                    </h5>
                  </div>
                </div>
              </form>

              {/* </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
