import React, { useEffect, useState } from "react";
import { Columns } from "../../../interfaces/_common";
import protectedApiService from "../../../services/_protected_api";
import PrimeDataTable from "../../../common/prime_data_table";
import userState from "../../../store/_userState";
import Loader2 from "../../../common/loader2";
export default function Facultydashboard() {
  const { get_faculty_upcoming_classes, get_faculty_details_all } = protectedApiService();
  const { user } = userState();
  const [alldata, setalldata] = useState<any>(null);
const [ratings, setratings] = useState<any>(null);
const [class_completed, setclass_completed] = useState<any>(null);

  const tablesStructure: Columns[] = [
    {
      data_name: "course_code",
      header: "Course Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "date",
      header: "Date",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "start_time",
      header: "Time",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
  ];

  useEffect(() => {
    get_faculty_upcoming_classe();
    get_fac_details();
  }, []);

  const get_faculty_upcoming_classe = async () => {
    const get_data = await get_faculty_upcoming_classes(user.user_id);
    // console.log(get_data);
    setalldata(get_data);
  };

  const get_fac_details = async()=>{
    const data = await get_faculty_details_all(user.user_id);
    //console.log(data);
    
    setratings(data.ratings);
    setclass_completed(data.class_completed);
    
  }

  return (
    <>
      {/* <h3>Upcoming Classes</h3> */}
      {alldata ? (
        <>
          {" "}
          <div className="container">
            <div className="row">
              <div className="col-md-2 cust_sec">
                <div
                  className="faculty_dash_card"
                  style={{
                    display: "flex",
                    background: "#DFF1FF",
                    padding: "10px 15px",
                    margin: "10px 0",
                  }}
                >
                  <div>
                    <img
                      src="/assets/img/Classes_Completed.png"
                      style={{ width: "60px", height: "60px" }}
                    />
                  </div>
                  <div>
                    <h3 style={{ color: "#0082C1", fontWeight: "bold" }}>{class_completed}</h3>
                    <p style={{ fontSize: "12px" }}>Class Completed</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 cust_sec">
                <div
                  className="faculty_dash_card"
                  style={{
                    display: "flex",
                    background: "#FFF9E1",
                    padding: "10px 15px",
                    margin: "10px 0",
                  }}
                >
                  <div>
                    <img
                      src="/assets/img/Star.png"
                      style={{ width: "60px", height: "60px" }}
                    />
                  </div>
                  <div>
                    <h3 style={{ color: "#FDB230", fontWeight: "bold" }}>
                      {ratings}
                    </h3>
                    <p style={{ fontSize: "12px" }}>Student Ratings</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PrimeDataTable
            data={alldata || []}
            structure={tablesStructure}
            title={"Upcoming Classes"}
            onRefresh={get_faculty_upcoming_classe}

            // options
          />
        </>
      ) : (
        <>
          <Loader2 />
        </>
      )}
    </>
  );
}
