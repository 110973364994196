import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

export default function StudentDashboard() {

useEffect(()=>{
///////////////  Job Submit ////////////////

var job_data = localStorage.getItem("dataspacemanagementlearning_job_ref");

var skillup_data = localStorage.getItem("dataspacemanagementlearning_skillup_ref");

//console.log(job_data);
if(job_data!=null){
  window.location.href = window.location.origin+"/Home/Jobs/All%20Jobs/Selected%20Job";
}

if(skillup_data!=null){
  window.location.href = window.location.origin+"/SkillUp/Modules";
}




},[])


  return (
    <>
      <div className=" s-dashboard">
        <div className="row mt-4">
          <div className="col-sm-6  ">
            <Link to="/Enrolled">
              <div className="image m-2">
                <img src="/assets/student/live_interactive.png" alt="" />
              </div>
            </Link>
          </div>
          <div className="col-sm-6 ">
            <Link to="/Enrolled/Skill Up Courses">
              <div className="image m-2">
                <img src="/assets/student/skill_up_course.png" alt="" />
              </div>
            </Link>
          </div>
          <div className="col-sm-12  ">
            <a href="https://webinar.dataspaceacademy.com/">
              <div className="image m-2">
                <img src="/assets/student/Events.png" alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
