import { Link, useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useCallback, useEffect, useState } from "react";
import studentCommonApi from "../../../../services/_student_skillup_api";
import studentGlobalDataStore from "../../../../store/_global_studentData";
import { FilterDropdown } from "../../../../common/prime_data_table";
import protectedApiService from "../../../../services/_protected_api";
import Loader from "../../../../common/loader";
import userState from "../../../../store/_userState";

export default function AvailableQuiz(){
    const { get_available_quiz } = protectedApiService();
    const [quizset, setquizset] = useState<any>(null);

    const { user } = userState();

    useEffect(() => {
        fetchquiz();
      }, []);

const fetchquiz = async()=>{
const available = await get_available_quiz(user.user_id);
console.log(available);

setquizset(available);


}

const gotoquizsingle = (a)=>{

}

    return(
        <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato&amp;display=swap" />
         {!quizset && <Loader />}
        {quizset && (
  <div className="row px-4  sqm">
            <div className="col-md-12 border11 p-4 ">
                <div className="mcqs_section">
                    <div className="p-2 my-2">
                        <div className=" ">

{quizset.map((x, index)=>{
  if(x.quiz_details!=""){
    const get_de = JSON.parse(x.quiz_details);
    //console.log(get_de);
  }
  return(
    <>
    <div className="rww">
    <h3 className="fw-bold pb-2">
                               {x.course_name}
                                </h3>
                                <div className="row">
{JSON.parse(x.quiz_details).map((v,index)=>{
  return(
    <>
    <div className="col-md-4">
                                        <div className="card card_prop">
                                            <img src={v.img || "/assets/bg/register_bg.png"} className="card-img-top" alt="..." />
                                            <div className="card-body">
                                                <h6 className="card-title fw-bold">
                                                {v.quiz_title || ""}
                                                </h6>
                                               <div className="row">
                                                <div className="col-md-6">
                                                    <span>Attempted</span><p>{v.quiz_times==0 ? "Unlimited": v.quiz_times}</p>
                                                </div>
                                                <div className="col-md-6 margin_1 pe-0">
                                                    <div >
                                                        <span>Last Score</span><p>{v.quiz_last_result}/{v.total_questions}</p>
                                                    </div>
                                                  
                                                </div>

                                               </div>
                                               <Link
                              to="/Home/Global Exam Start"
                              state={v}
                            >
                                               <button className=" btn btn-success btn_retake"> Retake</button>
                                              </Link>
                                            </div>
                                        </div>
                                    </div>
    </>
  )

})}
                                    
                                   
                                  
                                    

                              

                        </div>

</div>

    </>
  )
})}



                    </div>
                </div>

            </div>

          </div>
          </div>
            )}
      </>
    );
}