import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import AdminDashboard from "../pages/home/admin/admin_dashboard";
import { RiDashboardFill, RiNewspaperLine } from "react-icons/ri";
import EditAdmin from "../pages/home/admin/edit_admin";
import { GoPerson } from "react-icons/go";
import AddAdmin from "../pages/home/admin/add_admin";
import ViewProfileDetails from "../pages/home/admin/common/view_profile";
import EditProfileDetails from "../pages/home/admin/common/edit_profile";
import StudentIndex from "../pages/home/admin/students/student_index";
import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import SendMessage from "../pages/home/admin/send_message";
import AllStudents from "../pages/home/admin/students/all_students";
import ViewStudentClass from "../pages/home/admin/students/live_interactive/view_student_class";
import LiveInteractiveStudents from "../pages/home/admin/students/live_interactive/outlet_live_ineractive_students";
import AddNewSchedule from "../pages/home/admin/students/live_interactive/new_schedule";
import ScheduledStudents from "../pages/home/admin/students/live_interactive/scheduled_students";
import NotScheduledStudents from "../pages/home/admin/students/live_interactive/not_scheduled_student";
import SetStudentClass from "../pages/home/admin/students/live_interactive/set_get_schedule_calender/set_student_class";
import CourseCompletedStudents from "../pages/home/admin/students/live_interactive/course_completed";
import CourseNotCompletedStudents from "../pages/home/admin/students/live_interactive/course_not_completed";
import ProjectAssignedStudents from "../pages/home/admin/students/live_interactive/project_assigned";
import ProjectNotAssignedStudents from "../pages/home/admin/students/live_interactive/project_not_assigned";
import InterviewStudents from "../pages/home/admin/students/live_interactive/Interview";
import InterviewNotCompleted from "../pages/home/admin/students/live_interactive/interview_not_completed";
import AllFaculty from "../pages/home/admin/faculty/all_faculty";
import SetFacultyTiming from "../pages/home/admin/faculty/set_faculty_timing";
import AddFaculty from "../pages/home/admin/faculty/add_faculty";
import AllMarketing from "../pages/home/admin/marketing/all_marketing";
import { BiNetworkChart, BiStats } from "react-icons/bi";
import AddMarketing from "../pages/home/admin/marketing/add_marketing";
import ReportsOutlet from "../pages/home/admin/reports/outlet_reports";
import { HiBriefcase, HiClipboardList } from "react-icons/hi";
import FacultyReport from "../pages/home/admin/reports/faculty_report";
import StudentsReport from "../pages/home/admin/reports/student_report";
import CareerOutlet from "../pages/home/admin/career/outlet_career";
import ViewAllJobs from "../pages/home/admin/career/view_all_jobes";
import EditJobs from "../pages/home/admin/career/edit_jobs";
import AddJobs from "../pages/home/admin/career/add_jobs";

import NewsView from "../pages/home/admin/news/news_view";
import AddNews from "../pages/home/admin/news/mews_add";
import Timeline from "../pages/home/admin/timeline";
import { IoMdWallet } from "react-icons/io";
import EditCourse from "../pages/settings/edit_course";
import AddCourse from "../pages/settings/add_course";
import Courses from "../pages/settings/courses";
import ViewStudentProfile from "../pages/home/student/student_profile";
import PaymentGateway from "../pages/home/admin/payment_gateway/payment_gateway";
import ReferredUser from "../pages/home/admin/referred_user/ref_user";
import Councellors from "../pages/settings/Councellors";
import Branches from "../pages/settings/branches";
import ClassReport from "../pages/home/admin/reports/class_report";
import StudentStatus from "../pages/home/admin/reports/student_status";
import Addbranches from "../pages/settings/add_branch";
import AddCouncellor from "../pages/settings/add_councellor";
import { FiSettings } from "react-icons/fi";
import { BsJournalBookmark } from "react-icons/bs";
import { AiOutlineShareAlt } from "react-icons/ai";
import { SiRazorpay } from "react-icons/si";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ViewAllApplied from "../pages/home/admin/career/view_apllied_jobs";
import UpdateSchedule from "../pages/home/admin/students/live_interactive/update_schedule";
import ScheduleList from "../pages/home/admin/students/live_interactive/schedule_list";
import Schedule from "../pages/home/faculty/Schedule";
import ViewClass from "../pages/home/admin/students/live_interactive/view_class";
import SubmittedResources from "../pages/home/admin/reports/submitted_resources";
import SupportEnquiry from "../pages/home/admin/reports/support_enquiry";
import AllStudentSkillUp from "../pages/home/admin/reports/skillupreport/all_student_skillup";
import StudentSkillUpStats from "../pages/home/admin/reports/skillupreport/see_skillup_status";
import StudentsReview from "../pages/home/admin/reports/student_review";
import Quizquestion from "../pages/home/admin/mcq_quiz/quiz_questions_list";
import CoursewiseQuestions from "../pages/home/admin/mcq_quiz/course_wise_questions";
import Quizlist from "../pages/home/admin/mcq_quiz/quiz_list";
import getMcqStudentsResults from "../pages/home/admin/mcq_quiz/get_mcq_students_result";
import PaymentOutlet from "../pages/home/marketing/outlet_payment";
import Viewallpayment from "../pages/home/admin/payment_gateway/view_all_payments";
import Junkusers from "../pages/home/admin/students/live_interactive/junk_users";
import Collegestudents from "../pages/home/admin/students/live_interactive/college_students";


export default function superAdminRoutings() {
  const routes: any[] = [
    {
      route: "Base",
      path: "",
      element: <Navigate to="Dashboard" />,
      children: [],
      shortcut: true,
    },
    {
      route: "Dashboard",
      path: "Dashboard",
      element: <AdminDashboard />,
      icon: <RiDashboardFill />,
      children: [],
      shortcut: true,
    },
    {
      route: "Admin",
      path: "Admin",
      element: <EditAdmin />,
      icon: <MdOutlineAdminPanelSettings />,
      children: [],
      shortcut: true,
    },
    {
      route: "Add Admins",
      path: "Add Admins",
      element: <AddAdmin />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "View Profile",
      path: "View Profile",
      element: <ViewProfileDetails />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "Edit Profile",
      path: "Edit Profile",
      element: <EditProfileDetails />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "Students",
      path: "Students",
      element: <StudentIndex />,
      icon: <GiGraduateCap />,
      shortcut: true,

      children: [
        {
          route: "Base",
          path: "",
          element: <Navigate to="All Students" />,
          children: [],
          shortcut: true,
        },
        {
          route: "Message",
          path: "Message",
          element: <SendMessage />,
          children: [],
          shortcut: false,
        },
        {
          route: "All Students",
          path: "All Students",
          element: <AllStudents />,
          children: [],
          shortcut: true,
        },
        {
          route: "View Class",
          path: "View Class",
          element: <ViewClass />,
          children: [],
          shortcut: false,
        },
        {
          route: "View Student Class",
          path: "View Student Class",
          element: <ViewStudentClass />,
          children: [],
          shortcut: false,
        },
        {
          route: "Live Interactive Students",
          path: "live",
          element: <LiveInteractiveStudents />,
          shortcut: true,
          children: [
            {
              route: "Base",
              path: "",
              element: <Navigate to="Scheduled Students" />,
              children: [],
              shortcut: false,
            },
            {
              route: "Schedule List",
              path: "Schedule List",
              element: <ScheduleList />,
              shortcut: true,
              children: [],
            },
            {
              route: "Add New Schedule",
              path: "Add New Schedule",
              element: <AddNewSchedule />,
              shortcut: true,
              children: [],
            },
            {
              route: "Update Schedule Data",
              path: "Update Schedule Data",
              element: <UpdateSchedule />,
              shortcut: false,
              children: [],
            },
            {
              route: "Scheduled Students",
              path: "Scheduled Students",
              element: <ScheduledStudents />,
              shortcut: true,
              children: [],
            },
            {
              route: "Not Scheduled Students",
              path: "Not Scheduled Students",
              element: <NotScheduledStudents />,
              shortcut: true,
              children: [],
            },
            {
              route: "Set Student Class",
              path: "Set Student Class",
              element: <SetStudentClass />,
              shortcut: false,
              children: [],
            },
            {
              route: "Course Completed",
              path: "Course Completed",
              element: <CourseCompletedStudents />,
              shortcut: true,
              children: [],
            },
            {
              route: "Course Complete Reminder",
              path: "Course Complete Reminder",
              element: <CourseNotCompletedStudents />,
              shortcut: true,
              children: [],
            },

            {
              route: "Project Assigned",
              path: "Project Assigned",
              element: <ProjectAssignedStudents />,
              children: [],
              shortcut: true,
            },
            {
              route: "Project Not Assigned",
              path: "Project Not Assigned",
              element: <ProjectNotAssignedStudents />,
              children: [],
              shortcut: true,
            },
            {
              route: "Interview Completed",
              path: "Interview Completed",
              element: <InterviewStudents />,
              children: [],
              shortcut: true,
            },
            {
              route: "Interview Not Completed",
              path: "Interview Not Completed",
              element: <InterviewNotCompleted />,
              children: [],
              shortcut: true,
            },

            {
              route: "College Students",
              path: "College Students",
              element: <Collegestudents />,
              children: [],
              shortcut: true,
            },
            {
              route: "Junk Users",
              path: "Junk Users",
              element: <Junkusers />,
              children: [],
              shortcut: true,
            },

            {
              route: "View Student",
              path: "View Student",
              element: <ViewStudentProfile />,
              children: [],
              shortcut: false,
            },
          ],
        },
      ],
    },
    {
      route: "Schedule",
      path: "Schedule",
      element: <Schedule />,
      icon: <GoPerson />,
      children: [],
      shortcut: false,
    },
    {
      route: "Profile",
      path: "Profile",
      element: <Outlet />,
      icon: <GoPerson />,
      children: [
        {
          route: "",
          path: "",
          element: <ViewStudentProfile />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Edit",
          path: "Edit",
          element: <ViewStudentProfile editable={true} />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
      ],
      shortcut: false,
    },
    {
      route: "Faculties",
      path: "faculties",
      element: <Outlet />,
      icon: <FaChalkboardTeacher />,
      no_collapse: true,
      children: [
        {
          route: "",
          path: "",
          element: <AllFaculty />,
          icon: <GoPerson />,
          children: [],
        },
        {
          route: "Set Faculty Timing",
          path: "Set Faculty Timing",
          element: <SetFacultyTiming />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Add Faculty",
          path: "Add Faculty",
          element: <AddFaculty />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
      ],
      shortcut: true,
    },

    {
      route: "Marketing",
      path: "Marketing",
      element: <Outlet />,
      icon: <BiStats />,
      no_collapse: true,
      children: [
        {
          route: "",
          path: "",
          element: <AllMarketing />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Add Marketing",
          path: "Add Marketing",
          element: <AddMarketing />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
      ],
      shortcut: true,
    },

    {
      route: "Reports",
      path: "reports",
      element: <ReportsOutlet />,
      icon: <HiClipboardList />,
      children: [
        {
          route: "Faculty Report",
          path: "Faculty Report",
          element: <FacultyReport />,

          children: [],
          shortcut: true,
        },
        {
          route: "Student Report",
          path: "Student Report",
          element: <StudentsReport />,

          children: [],
          shortcut: true,
        },
        {
          route: "Student Review",
          path: "Student Review",
          element: <StudentsReview />,

          children: [],
          shortcut: true,
        },
        {
          route: "Student Status",
          path: "Student Status",
          element: <StudentStatus />,

          children: [],
          shortcut: true,
        },
        {
          route: "Skill Up Status",
          path: "SkillUp Status",
          element: <Outlet />,
          no_collapse: true,
          children: [
            {
              route: "",
              path: "",
              element: <AllStudentSkillUp />,
              children: [],
              shortcut: false,
            },
            {
              route: "See Status",
              path: "See Status",
              element: <StudentSkillUpStats />,
              children: [],
              shortcut: false,
            },
          ],
          shortcut: false,
        },

        {
          route: "Class Details",
          path: "Class Details",
          element: <ClassReport />,

          children: [],
          shortcut: true,
        },
        {
          route: "Submitted Resources",
          path: "Submitted Resources",
          element: <SubmittedResources />,

          children: [],
          shortcut: true,
        },

        {
          route: "Support Enquiry",
          path: "Support Enquiry",
          element: <SupportEnquiry />,
          children: [],
          shortcut: true,
        },
      ],
      shortcut: true,
    },
    {
      route: "Career",
      path: "Career",
      element: <CareerOutlet />,
      icon: <HiBriefcase />,
      children: [
        {
          route: "View All Jobs",
          path: "View All Jobs",
          element: <ViewAllJobs />,

          children: [],
          shortcut: true,
        },
        {
          route: "Edit Jobs",
          path: "Edit Jobs",
          element: <EditJobs />,

          children: [],
          shortcut: false,
        },
        {
          route: "Add Jobs",
          path: "Add Jobs",
          element: <AddJobs />,
          children: [],
          shortcut: false,
        },
        {
          route: "Applied For Job",
          path: "appliedJob",
          element: <ViewAllApplied />,
          children: [],
          shortcut: true,
        },
      ],
      shortcut: true,
    },
    {
      route: "Settings",
      path: "settings",
      element: <Outlet />,
      icon: <FiSettings />,
      no_collapse: false,
      children: [
        {
          route: "",
          path: "",
          element: <AllFaculty />,
          icon: <GoPerson />,
          children: [],
        },
        {
          route: "Courses",
          path: "Courses",
          element: <Courses />,
          icon: <BsJournalBookmark />,
          children: [],
          shortcut: true,
        },
        {
          route: "Add Course",
          path: "Add Course",
          element: <AddCourse />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },

        {
          route: "Edit Course",
          path: "Edit Course",
          element: <EditCourse />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },

        {
          route: "Branches",
          path: "Branches",
          element: <Branches />,
          icon: <BiNetworkChart />,
          children: [],
          shortcut: true,
        },
        {
          route: "Add Branch",
          path: "Add Branch",
          element: <Addbranches />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Add Councellor",
          path: "Add Councellor",
          element: <AddCouncellor />,
          icon: <GoPerson />,
          children: [],
          shortcut: false,
        },
        {
          route: "Councellors",
          path: "Councellors",
          element: <Councellors />,
          icon: <GoPerson />,
          children: [],
          shortcut: true,
        },
      ],
      shortcut: true,
    },

    {
      route: "Referred User",
      path: "Referred User",
      element: <ReferredUser />,
      icon: <AiOutlineShareAlt />,
      children: [],
      shortcut: true,
    },
    // {
    //   route: "Payment Gateway",
    //   path: "p_gateway",
    //   element: <PaymentGateway />,
    //   icon: <SiRazorpay />,
    //   children: [],
    //   shortcut: true,
    // },


    {
      route: "Payment",
      path: "payment",
      element: <PaymentOutlet />,
      icon: <IoMdWallet />,
      shortcut: true,
      children: [
        {
          route: "View Payments",
          path: "view payments",
          element: <Viewallpayment />,
          children: [],
          shortcut: true,
        },
      ]
    },

    {
      route: "News",
      path: "news",
      element: <Outlet />,
      icon: <RiNewspaperLine />,
      no_collapse: true,
      children: [
        {
          route: "",
          path: "",
          element: <NewsView />,
          icon: <RiNewspaperLine />,
          children: [],
          shortcut: false,
        },
        {
          route: "Add News",
          path: "Add News",
          element: <AddNews />,
          icon: <RiNewspaperLine />,
          children: [],
          shortcut: false,
        },
      ],
      shortcut: true,
    },

    // {
    //   route: "Notes",
    //   path: "notes",
    //   element: <DataTable />,
    //   icon: <RiNewspaperLine />,
    //   children: [],
    //   shortcut: true,
    // },


    {
      route: "MCQ Quiz",
      path: "mcq-quiz",
      element: <CareerOutlet />,
      icon: <HiBriefcase />,
      children: [
        // {
        //   route: "All Quiz Data",
        //   path: "View All Jobs",
        //  // element: <ViewAllJobs />,

        //   children: [],
        //   shortcut: true,
        // },
        {
          route: "Quiz Questions",
          path: "Quiz Questions",
          element: <Quizquestion />,

          children: [],
          shortcut: true,
        },
        {
          route: "Course wise questions",
          path: "Course wise questions",
          element: <CoursewiseQuestions />,
          children: [],
          shortcut: false,
        },

        {
          route: "Quiz List",
          path: "Quiz List",
          element: <Quizlist />,
          children: [],
          shortcut: true,
        },

     

       

        // {
        //   route: "Applied For Job",
        //   path: "appliedJob",
        //   element: <ViewAllApplied />,
        //   children: [],
        //   shortcut: true,
        // },
      ],
      shortcut: true,
    },

    // {
    //   route: "MCQ Student Result",
    //   path: "MCQ Student Result",
    //   element: <getMcqStudentsResults />,
    //   children: [],
    //   shortcut: true,
    // },

    {
      route: "Message",
      path: "Message",
      element: <SendMessage />,
      children: [],
      shortcut: false,
    },
    {
      route: "Timeline",
      path: "Timeline",
      element: <Timeline />,
      children: [],
      shortcut: false,
    },
  ];
  return routes;
}
