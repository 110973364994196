import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useCallback, useEffect, useRef, useState } from "react";
import studentCommonApi from "../../../../services/_student_skillup_api";
import studentGlobalDataStore from "../../../../store/_global_studentData";
import { FilterDropdown } from "../../../../common/prime_data_table";
import protectedApiService from "../../../../services/_protected_api";
import Loader from "../../../../common/loader";
import userState from "../../../../store/_userState";
import { toast } from "react-toastify";
export default function Lessonstart() {
  const location = useLocation();
  const navigate = useNavigate();
  var al = { chapter: "" };
  const [lesson_content, setlesson_content] = useState<any>(al);
  const [new_le_content, setnew_le_content] = useState([]);
  const [last_array, setlast_array] = useState([]);
  const [slide_complete, setslide_complete] = useState(0);
  const [total_slides, settotal_slides] = useState<any>("");
  const [present_slides, setpresent_slides] = useState<any>("");
  const profile = location.state;

  const myRef: any = useRef();

  useEffect(() => {
    //  console.log(profile);
    var st = JSON.parse(profile.lesson_content);

    //  console.log(st);
    setnew_le_content(st);
    settotal_slides(st.length);
    setpresent_slides(1);
    // console.log(st);
    var llo = [];
    var kc = 1;
    st.forEach((vv) => {
      // console.log(vv);
      //   console.log(vv["chapter"]);
      vv["lesson_number"] = kc;
      kc++;
    });
    // var coc = st;
    //     var lastItem = coc.pop();
    //     setlast_array(lastItem)
    setlesson_content(st[0]);
  }, []);

  const data_next = () => {
    document.getElementById("scrl").scrollTo(0, 0);
    // console.log(new_le_content);

    const scrl = document.getElementById("scrl").scroll();

    console.log(scrl);

    var new_vk = lesson_content["lesson_number"] + 1;
    setpresent_slides(new_vk);
    new_le_content.forEach((vk) => {
      if (vk["lesson_number"] == new_vk) {
        setlesson_content(vk);
      }
    });
    //console.log(last_array);
    if (new_le_content.length == new_vk) {
      // console.log("last step called");
      setslide_complete(1);
    }
    // get the target div by its ID
    const targetDiv = $("#protalBoard");

    // scroll to the top of the div
    targetDiv.scrollTop(0);
  };

  const complete = () => {
    var u = { course_id: profile.course_id };
    var dd = localStorage.getItem("source");
    if(dd=="paid"){
      localStorage.removeItem("source");
      navigate(`/StudentClasses/Lessons`);
    }else{
    navigate(`/SkillUp/Lessons`, { state: u });
    }
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Lato&amp;display=swap"
      />
      {/* <div className="col-md-6 text-end  align-self-end">
                <p className="timeandquestion heading">Question : {quiz_question_single.question_no}/{quiz_details.total_questions} <span className="px-3">|</span>Time Left : {minutes}min {seconds}secs
                </p>
            </div> */}
      <div className="container cntn">
        <div className="row px-3  ">
          <div className="col-md-12 border11 p-4 ">
            <div className="mcqs_section">
              <div style={{ float: "right" }}>
                {present_slides} / {total_slides}
              </div>
              <div className="p-4 my-2">
                <div className="row ">
                  <div
                    className="cust_clsc"
                    id="scrl"
                    dangerouslySetInnerHTML={{ __html: lesson_content.chapter }}
                  ></div>
                </div>

                <div
                  className="row justify-content-end align-items-end buttons_end"
                  style={{ marginTop: "3rem" }}
                >
                  <div className="col text-end align-self-end">
                    {slide_complete == 1 ? (
                      <>
                        <button
                          className="complete"
                          onClick={() => {
                            complete();
                          }}
                        >
                          Complete
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="next"
                          onClick={() => {
                            data_next();
                          }}
                        >
                          Next
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
