import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StudentAddCheck from "../../store/_student_add_check";
import protectedApiService from "../../services/_protected_api";
import Fullloader from "../../common/full_loader";

export default function Jobaggrdashboard(){
    const {getAllStudents } =
    protectedApiService();
    const [ck_en, setck_em] = useState<any>(null);

    useEffect(()=>{
        getFromApi();
    },[])

    const getFromApi = async () => {
        var student_details = localStorage.getItem("get_student_details");
        
        if(student_details==null){
          
          const res: any = await getAllStudents();
    
          setck_em(res);
         
          //console.log(res);
          localStorage.setItem("get_student_details", btoa(JSON.stringify(res)));
          const stu = StudentAddCheck();
        }else{
          setck_em(student_details);
        }
      
      };

    return(
       <>
       {!ck_en && <Fullloader />}
       </>
    )
}