import studentGlobalDataStore from "../../../../store/_global_studentData";
import { useEffect, useState, useRef } from "react";
import studentCommonApi from "../../../../services/_student_skillup_api";
import { Link } from "react-router-dom";
export default function Showlesson() {
  const { skillUpModule, setSkillUpModule } = studentGlobalDataStore();
  const { get_lesson_course } = studentCommonApi();

  const [get_result, setget_result] = useState([]);

  useEffect(() => {
    get_student_lesson();
  }, []);

  const get_student_lesson = async () => {
    const get_lesson = await get_lesson_course(skillUpModule.course_id, "free");
    console.log(get_lesson);
    setget_result(get_lesson);
  };

  return (
    <>
      <div className="rww">
        <h3 className="fw-bold pb-2"></h3>
        <div className="row">
          {get_result.map((x, index) => {
            return (
              <>
                <div className="col-sm-3">
                  <div className="card lesson-card p-3">
                    <div className="flex-center flex-column">
                      <img src="/assets/svg/video-lesson.svg" alt="" />
                    </div>
                    <div className="text-start">
                      <p>Lesson - {index + 1}</p>
                      <p className="heading">{x.lesson_name || ""}</p>
                    </div>
                    <Link to="/SkillUp/lesson start" state={x}>
                      <button className="btn btn-primary btn_retake">
                        Show
                      </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div
                    className="card card_prop"
                    style={{ margin: "15px auto" }}
                  >
                    <img
                      src={"/assets/bg/register_bg.png"}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h6 className="card-title fw-bold">
                        {v.lesson_name || ""}
                      </h6>

                      <Link to="/SkillUp/lesson start" state={v}>
                        <button className=" btn btn-success btn_retake">
                          {" "}
                          Show
                        </button>
                      </Link>
                    </div>
                  </div>
                </div> */}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
