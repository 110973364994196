import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import { RiDashboardFill, RiNewspaperLine } from "react-icons/ri";
import PaymentOutlet from "../pages/home/marketing/outlet_payment";
import { IoMdWallet } from "react-icons/io";
import Viewallpayment from "../pages/home/admin/payment_gateway/view_all_payments";
import Accountuserdashboard from "../pages/home/account_user";
import AddFaculty from "../pages/home/admin/faculty/add_faculty";
import { GoPerson } from "react-icons/go";
import SetFacultyTiming from "../pages/home/admin/faculty/set_faculty_timing";
import AllFaculty from "../pages/home/admin/faculty/all_faculty";
import { FaChalkboardTeacher } from "react-icons/fa";

export default function Facultymonitor() {
  const routes: any[] = [
    {
      route: "Base",
      path: "",
      element: <Navigate to="Dashboard" />,
      children: [],
      shortcut: true,
    },
    {
      route: "Dashboard",
      path: "Dashboard",
      element: <Accountuserdashboard />,
    //element: <AdminDashboard />,
      icon: <RiDashboardFill />,
      children: [],
      shortcut: true,
    },
    {
        route: "Faculties",
        path: "faculties",
        element: <Outlet />,
        icon: <FaChalkboardTeacher />,
        no_collapse: true,
        children: [
          {
            route: "",
            path: "",
            element: <AllFaculty />,
            icon: <GoPerson />,
            children: [],
          },
          {
            route: "Set Faculty Timing",
            path: "Set Faculty Timing",
            element: <SetFacultyTiming />,
            icon: <GoPerson />,
            children: [],
            shortcut: false,
          },
        //   {
        //     route: "Add Faculty",
        //     path: "Add Faculty",
        //     element: <AddFaculty />,
        //     icon: <GoPerson />,
        //     children: [],
        //     shortcut: false,
        //   },
        ],
        shortcut: true,
      },
  ];
  return routes;
}
