export default function LocalstorageStudentData(){
    var data = JSON.parse(atob(localStorage.getItem("get_student_details")));
   

    const map = new Map();
     var akpk = [];
    
         for (const item of data) {
             if (!map.has(item.student_id)) {
                 map.set(item.student_id, true);    // set any value to Map
                 akpk.push(item);
     
     
             }
         }  

    return akpk;
}