import { useEffect, useState } from "react";
import "react-image-picker-editor/dist/index.css";
import protectedApiService from "../../../../services/_protected_api";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import commonApiService from "../../../../services/_common_api";
import globalDataStore from "../../../../store/_globalData";
import { Chips } from "primereact/chips";
import { Editor } from "primereact/editor";
export default function EditJobs() {
  const location = useLocation();
  const job: any = location.state;
  const init: any = {
    job_id: "",
    title: "",
    education: "",
    location: "",
    company: "",
    experience: "",
    job_role: "",
    terms_condition: "",
    course_id: "",
    qualification: "",
    duration: "Full Time",
    package: "",
    company_logo:job.company_logo
  };
  const [creeds, setCreeds] = useState({ ...init, ...job });
  const [image_update, setimage_update] = useState<any>(0);

  const [company_image, setcompany_image] = useState<any>(null);

  const { postUpdateJob } = protectedApiService();
  const onValueChange = (val: any) => {
    console.log(val);
    setCreeds({ ...creeds, ...val });
  };

  const [course, setCourse] = useState<any>(null);
  const [job_description, onTextChange] = useState<string>(creeds.job_description);
  const [qualification, onTextChange_quali] = useState<string>(creeds.qualification);
  const { getAllCourses } = commonApiService();
  const { allCourses } = globalDataStore();
  var veri:any = [];
 if(creeds.skills!=null){

 veri = JSON.parse(creeds.skills);

 }
  const [skills, onSkillsAdd] = useState<any[]>(veri);
  const getCourse = async () => {
    const res: any = await getAllCourses();
    setCourse(res);
  };
  useEffect(() => {
    if (allCourses) {
      setCourse(allCourses);
    } else {
      getCourse();
    }
  }, []);


const company_image_update = (e)=>{
  setcompany_image(e.target.files[0]);
  setimage_update(1);

}

  const onSubmit = async () => {

    var fd = new FormData();
    fd.append("job_id",job.job_id);
    fd.append("title",creeds.title);
    fd.append("education",creeds.education);
    fd.append("location",creeds.location);
    fd.append("company",creeds.company);
    if(image_update==0){
      fd.append("company_logo", creeds.company_logo);

    }else{
      fd.append("company_logo",company_image, company_image.name);
    }
    
    fd.append("image_update",image_update);
    fd.append("experience",creeds.experience);
    //fd.append("job_role",creeds.job_role);
    fd.append("terms_condition", creeds.terms_condition);
    fd.append("course_id",creeds.course_id);
  //  fd.append("qualification", qualification);
    fd.append("duration", creeds.duration);
    fd.append("package", creeds.package);
    fd.append("job_description", job_description);
    fd.append("skills", JSON.stringify(skills));


    const res: any = await postUpdateJob(fd);
    console.log(res);
    if (res == 1) {
      toast.success("Updated");
      setCreeds(creeds);
    } else {
      toast.error("Updating failed!");
    }
  };

  return (
    <>
      <div className=" mt-3">
        <h5>Job Details</h5>
        <div className="card shadow mt-3 p-4">
          <div className="row mx-3">
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Job Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  id="title"
                  value={creeds.title}
                  aria-describedby="namelHelp"
                  placeholder="Title"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

{course && (
              <div className="col-sm-6 ">
                <div className="mb-3">
                  <label htmlFor="course" className="form-label">
                    Related Course
                  </label>
                  <select
                    className="form-select"
                    name="course_id"
                    id="course_id"
                    defaultValue={creeds.course_id}
                    required
                    onChange={(e) =>
                      onValueChange({
                        [e.target.name]: e.target.value,
                      })
                    }
                  >
                    <option value="default" selected>
                      Select Course
                    </option>
                    {course.map((x: any) => (
                      <option value={x.course_id}>{x.page_name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
<div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="course" className="form-label">
                  Job Duration
                </label>
                <select
                  className="form-select"
                  name="duration"
                  id="duration"
                  defaultValue={creeds.duration}
                  required
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option value="default" disabled selected hidden>
                    Select Course
                  </option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  id="company"
                  value={creeds.company}
                  aria-describedby="namelHelp"
                  placeholder="example"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>


            <div className="col-sm-6">
              <div className="mb-3">
                <img src={`https://dataspaceacademylearning.com/api/public/company/${job.company_logo}`} style={{width:"100px", height:"100px;"}} />
                <label htmlFor="name" className="form-label">
                  Company Logo
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="company_logo"
                  id="company_logo"
                
                  aria-describedby="namelHelp"
                  placeholder="example"
                  onChange={(e)=>{company_image_update(e)}}
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="contact_no" className="form-label">
                  Package
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="package"
                  id="package"
                  value={creeds.package}
                  aria-describedby="namelHelp"
                  placeholder="10 - 15 L / Not disclosable"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Degree
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="education"
                  id="education"
                  value={creeds.education}
                  aria-describedby="namelHelp"
                  placeholder="Education Details"
                 
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="contact_no" className="form-label">
                  Experience
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="experience"
                  id="experience"
                  value={creeds.experience}
                  aria-describedby="namelHelp"
                  placeholder="Years"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Education
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="education"
                  id="education"
                  value={creeds.education}
                  aria-describedby="namelHelp"
                  placeholder="B.Tech"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  id="location"
                  value={creeds.location}
                  aria-describedby="namelHelp"
                  placeholder="14H Road, example "
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Job Role
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="job_role"
                  id="job_role"
                  value={creeds.job_role}
                  aria-describedby="namelHelp"
                  placeholder="Senior Programmer II"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div> */}
            <div className="col-sm-6">
              <div className="mb-3 ">
                <p className="form-label">Skills</p>
                <Chips
                  width={"100%"}
                  separator=","
                  value={skills}
                  onChange={(e) => onSkillsAdd(e.value)}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Terms and Conditions
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="terms_condition"
                  id="terms_condition"
                  value={creeds.terms_condition}
                  aria-describedby="namelHelp"
                  placeholder="T&C "
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/* <div className="col-sm-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Qualification
                </label>
                <Editor
                  style={{ height: "180px" }}
                  value={creeds.qualification}
                  name="qualification"
                  onTextChange={(e: any) => onTextChange_quali(e.htmlValue)}
                />
              </div>
            </div> */}


            <div className="col-sm-12">
              <div className="mb-12">
                <label htmlFor="name" className="form-label">
                  Job Description
                </label>
                {/* <input
                  type="text"
                  className="form-control"
                  name="job_description"
                  id="job_description"
                  value={creeds.job_description}
                  aria-describedby="namelHelp"
                  placeholder="Description"
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                /> */}

<Editor
                  style={{ height: "180px" }}
                  value={creeds.job_description}
                  name="job_description"
                  onTextChange={(e: any) => onTextChange(e.htmlValue)}
                />
              </div>
            </div>
          </div>
          <div className="flex-start p-3 mx-3">
            <button onClick={onSubmit} className="btn btn-primary">
              Update Job Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
