import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { useEffect, useRef, useState } from "react";
import { BsClockHistory } from "react-icons/bs";
import { IoBriefcaseOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import { SlGraduation } from "react-icons/sl";
import protectedStudentApiService from "../../../../services/_protected_student_api";
import Loading from "../../../../common/loader";
import ShowMoreText from "react-show-more-text";
import Loader3 from "../../../../common/loader3";
import {
  DateFilterDropdown,
  FilterDropdown,
} from "../../../../common/prime_data_table";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import Loader2 from "../../../../common/loader2";
export default function StudentJobs() {
  const [allJobs, setAllJobs] = useState<any>(null);
  const [changeableData, setChangeableData] = useState<any>(null);
  const { getAllJobs } = protectedStudentApiService();
  const filterBy = [
    {
      header: "Company",
      filter: "company",
    },
    {
      header: "Salary",
      filter: "package",
    },
    {
      header: "Company",
      filter: "company",
    },
    {
      header: "Experience",
      filter: "experience",
    },
    {
      header: "Duration",
      filter: "duration",
    },
    {
      header: "Location",
      filter: "location",
    },
  ];
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const resp: any = await getAllJobs();
    setAllJobs(resp);
    setChangeableData(resp);
  };
  const op = useRef(null);
  const onSearch = (search: any) => {
    let keys: any = Object.keys(allJobs[0]);
    // console.log(keys);
    let searchedData: any[] = allJobs.filter((d: any) => {
      let checker: any[] = [];
      keys.map((k: any) => {
        var strRegExPattern = `${search}.*`;
        if (d[k]) {
          if (typeof d[k] != "string") {
            if (
              d[k]
                .toString()
                .toLowerCase()
                .match(new RegExp(strRegExPattern, "g"))
            )
              checker.push(1);
          } else {
            if (
              new RegExp(strRegExPattern.toLocaleLowerCase(), "g").test(
                d[k].toLowerCase()
              )
            )
              checker.push(1);
          }
        }
        return 0;
      });
      if (checker.length) {
        return true;
      } else {
        return false;
      }
    });
    setChangeableData(searchedData);
  };
  return (
    <>
      <>
        <div className="container enrolled mt-2 ">
          <div className="row mx-auto">
            <div className="heading col-sm-4">
              <h5 className="heading">All Available Job</h5>
            </div>
            {allJobs && (
              <div className="col-sm-8 flex-end">
                <div className="search d-flex">
                  {allJobs && (
                    <div className="filter p-1">
                      <Button
                        style={{
                          height: "100%",
                          width: "2.5rem",
                        }}
                        icon="pi pi-filter"
                        className="p-button-rounded "
                        aria-label="Filter"
                        onClick={(e) => op.current.toggle(e)}
                      />
                      <OverlayPanel
                        style={{ maxHeight: "25rem", overflow: "auto" }}
                        ref={op}
                      >
                        <div className="flex-end">
                          <Button
                            icon="pi pi-refresh"
                            className="p-button-rounded "
                            label="reset"
                            onClick={(e) => setChangeableData(allJobs)}
                          />
                        </div>
                        {allJobs &&
                          filterBy.map((fil: any) => (
                            <>
                              <FilterDropdown
                                allData={allJobs}
                                changeableData={changeableData}
                                filterField={fil.filter}
                                setChangeableData={setChangeableData}
                                header={fil.header}
                              />
                            </>
                          ))}
                      </OverlayPanel>
                    </div>
                  )}

                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="search"
                    style={{ minWidth: "16rem" }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="row p-1">
            {!allJobs ? (
              <Loader2 />
            ) : (
              <>
                {" "}
                {changeableData && allJobs.length ? (
                  changeableData.map((x: any) => (
                    <Link
                      className="col-sm-6 p-2 "
                      to="Selected Job"
                      state={{ job: x, allJobs: allJobs }}
                    >
                      <div className="card p-4 jobs-card">
                        <div className="row">
                          <div className="col-sm-6">
                            <h5 className="heading">
                              {x.title || "Front-End Engineer React JS"}
                            </h5>
                          </div>
                          <div className="col-sm-6 flex-end">
                            <h5 className="heading text-primary">
                              {x.package || "Rs. 10 - 15 LPA"}
                            </h5>
                          </div>
                        </div>
                        <p className="text-gray">
                          {" "}
                          {x.company || "Numax Data Lab Pvt. Ltd."}
                        </p>
                        <div className="row ">
                          <div className="chips     ">
                            <IoBriefcaseOutline className="mx-2" />{" "}
                            {x.experience} years
                          </div>
                          <div className="chips    ">
                            <BsClockHistory className="mx-2" />
                            {x.duration}
                          </div>
                          <div className="chips   ">
                            <GrLocation className="mx-2" />{" "}
                            {x.location || "Hyderabad"}
                          </div>
                          <div className="chips    ">
                            <SlGraduation className="mx-2" />
                            {x.education || ""}
                          </div>
                        </div>
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          className="text-gray mt-2"
                          anchorClass="show-more-less-clickable"
                          expanded={false}
                          width={280}
                          truncatedEndingComponent={"... "}
                        >
                          {" "}
                          <div
                            className="text-gray"
                            dangerouslySetInnerHTML={{
                              __html: x.job_description,
                            }}
                          ></div>
                        </ShowMoreText>
                      </div>
                    </Link>
                  ))
                ) : (
                  <>
                    <Loader3 />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
}
